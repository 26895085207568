import React from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import {
    ROUTE_PRODUCTS_CATEGORIES,
    ROUTE_PRODUCTS_ATTRIBUTS,
    ROUTE_PRODUCTS_COMPLETUDE,
    ROUTE_MEDIAS_CATEGORIES,
    ROUTE_MEDIAS_ATTRIBUTS,
    ROUTE_MEDIAS_TAGS,
    ROUTE_CONTENU_CATEGORIES,
    ROUTE_CONTENU_ATTRIBUTES
} from '../../../../js/constants/route-names';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import { checkRouting } from '../../../../js/utils/checkRouting';
import OurTypography from '../../../ui/typography/Typography';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import PageInfoImage from '../../../../assets/images/dashboard/dashboard_flux_de_travail.png';
import request from '../../../../js/utils/fetch';
import { CSV, IMAGES, OTHERS, VIDEOS } from '../../../../js/constants/medias-types';
import moment from 'moment';
import { GET_CATEGORIES_ONLY } from '../../../../queries/categories';
import { withApollo } from 'react-apollo';
import { GET_PRODUCTS_TOTAL_INFOS } from '../../../../queries/products';

const TitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
`;
const SubTitleBis = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 31px;
    line-height: 39px;
    width: 100%;
    @media screen and (max-width: 1600px){
        font-size: 23px;
        line-height: 24px;
    }
    @media screen and (max-width: 1450px){
        font-size: 18px;
        line-height: 19px;
    }
`;
const MoreInfosBis = styled(Typography)`
    margin-top: 4px;
    color: ${colors.black.regular};
    font-size: 12px;
    line-height: 14px;
    width: 100%;
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    min-height: 210px;
`;

const IconWrapper = styled(Grid)`
    background-color: ${colors.blue.darker.hue300};
    padding: 7.5px;
    border-radius: 10px;
    margin-right: 10px;
`;

const BoxCustom = styled(Box)`
    color: ${colors.blue.darker.hue300};
    svg circle{
        stroke: ${colors.blue.darker.hue300};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${colors.blue.darker.hue300};
    }
`;

class SettingsTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            listSettings: [],
            pageInfoSettings: null,
            stockageCat: [
                {
                    mimetypes: VIDEOS,
                },
                {
                    mimetypes: IMAGES,
                },
                {
                    mimetypes: CSV,
                },
                {
                    mimetypes: OTHERS,
                },
            ],
            contents: [],
        }
    }

    initPageInfo = (type) => {
        let pageInfo = null;
        switch (type) {
            case 'products':
                pageInfo = {
                    title: 'Comment configurer vos produits',
                    description: 'Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée.',
                    image: PageInfoImage,
                    datas: [
                        {
                            title: 'Nombre total de catégorie',
                            subtitle: this.state.productCategories.length + ' Categories',
                            description: 'Dernier ajout :',
                            descriptionData: '-',
                            icon: 'picto-produit',
                            value: this.state.productCategories.length && 20,
                        },
                        {
                            title: 'Nombre total de produits',
                            subtitle: this.state.productsData.nbProducts + ' Produits',
                            description: 'Dernier ajout :',
                            descriptionData: this.state.productsData.dateLastProduct ? moment(this.state.productsData.dateLastProduct).format('DD/MM/YYYY à HH:mm') : 'N/A',
                            icon: 'picto-produit',
                            value: this.state.productsData.nbProducts && 40,
                        },
                    ]
                }
                break;
            case 'medias':
                console.log('>>>>', this.state.remainginSize);
                pageInfo = {
                    title: 'Comment configurer vos médias',
                    description: 'Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée.',
                    image: PageInfoImage,
                    datas: [
                        {
                            title: 'Nombre total',
                            subtitle: 'Stockage utilisé',
                            description: 'Dernière MAJ :',
                            descriptionData: '21/07/2022',
                            color: colors.red.lighter,
                            value: this.state.stockageCat.reduce((acc, item) => {
                                return (acc + item.size) / 1024 / 1024 / 1024 / 1024;
                            }, 0).toFixed(1) + ' To',
                        },
                        {
                            title: 'Nombre total',
                            subtitle: 'Stockage libre',
                            description: 'Dernier ajout :',
                            descriptionData: '21/07/2022',
                            value: (this.state.remainginSize / 1024 / 1024 / 1024 / 1024).toFixed(1) + ' To',
                        },
                    ]
                }
                break;
            case 'contents':
                pageInfo = {
                    title: 'Comment configurer vos contenus',
                    description: 'Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée.',
                    image: PageInfoImage,
                    datas: [
                        {
                            // title: 'Nombre total de catégorie',
                            subtitle: this.state.contents.nbCategories + ' Categories',
                            description: `Dernier ajout:`,
                            descriptionData: this.state.contents.dateLastCategory ? moment(this.state.contents.dateLastCategory).format('DD/MM/YYYY à HH:mm') : 'N/A',
                            icon: 'picto-produit',
                            value: this.state.contents.nbCategories && 20,
                        },
                        {
                            // title: 'Nombre total de produits',
                            subtitle: this.state.contents.nbContents + ' Contenus',
                            description: 'Dernier ajout :',
                            descriptionData: this.state.contents.dateLastContent ? moment(this.state.contents.dateLastContent).format('DD/MM/YYYY à HH:mm') : 'N/A',
                            icon: 'picto-produit',
                            value: this.state.contents.nbContents && 40,
                        },
                    ]
                }
                break;
            default:
                break;
        }
        this.setState({
            loading: false,
            pageInfoSettings: pageInfo
        })
    };

    initCards = (type) => {
        let settingsList = [];
        switch (type) {
            case 'products':
                settingsList = [
                    {
                        name: 'drawer.categories',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_PRODUCTS_CATEGORIES,
                        icone: 'picto-produit',
                    },
                    {
                        name: 'drawer.attributes',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_PRODUCTS_ATTRIBUTS,
                        icone: 'picto-produit',
                    },
                    {
                        name: 'drawer.completeness',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_PRODUCTS_COMPLETUDE,
                        icone: 'picto-produit',
                    },
                ]
                break;
            case 'medias':
                settingsList = [
                    {
                        name: 'drawer.categories',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_MEDIAS_CATEGORIES,
                        icone: 'picto-produit'
                    },
                    // {
                    //     name : 'drawer.attributes',
                    //     route: ROUTE_MEDIAS_ATTRIBUTS,
                    //     icone: 'picto-attribut'
                    // },
                    {
                        name: 'drawer.tags',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_MEDIAS_TAGS,
                        icone: 'picto-produit'
                    },
                ]
                break;
            case 'contents':
                settingsList = [
                    {
                        name: 'drawer.categories',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_CONTENU_CATEGORIES,
                        icone: 'picto-produit'
                    },
                    {
                        name: 'drawer.models',
                        description: 'Texte qui explique comment configurer les catégories et comment gérer sont arborescence.',
                        route: ROUTE_CONTENU_ATTRIBUTES,
                        icone: 'picto-produit'
                    },
                ]
                break;
            default:
                break;
        }
        this.setState({
            loading: false,
            listSettings: settingsList
        })
    };

    getPageData = () => {
        switch (this.props.typeSettings) {
            case 'products':
                this.getProductsData();
                break;
            case 'medias':
                this.getStorage();
                break;
            case 'contents':
                this.getContentsData();
                break;
            default:
                break;
        }
    }

    componentDidMount = () => {
        checkRouting(this.props)
        this.getPageData();
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.typeSettings !== this.props.typeSettings) {
            this.setState({
                loading: true
            })
            this.getPageData();
        }
    }

    getProductsData = () => {
        this.props.client.query({
            query: GET_PRODUCTS_TOTAL_INFOS,
        }).then((result) => {
            const latestDate = new Date(Math.max(...result.data.products.edges.map(item => new Date(item.node.created_at))));

            this.setState({
                productsData: {
                    nbProducts: result.data.products.totalCount,
                    dateLastProduct: latestDate,
                },
            }, () => {
                this.props.client.query({
                    query: GET_CATEGORIES_ONLY,
                    variables: {
                        "exists": [{
                            "catalog": false
                        }]
                    },
                }).then((result) => {
                    this.setState({
                        productCategories: result.data.categories,
                    }, () => {
                        this.initPageInfo(this.props.typeSettings);
                        this.initCards(this.props.typeSettings);
                    })
                })
            })
        })
    }

    getContentsData = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/content`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        contents: data.datas,
                    }, () => {
                        this.initPageInfo(this.props.typeSettings);
                        this.initCards(this.props.typeSettings);
                    });
                }
            }
        ).catch(e=>{            
            this.setState({
                contents: [],
            });
        })
    }

    getStorage = async () => {
        request(`${process.env.REACT_APP_API}/media-objects-size`).then(async (data) => {
            console.log('STORAGE', data);
            let result = data.response;

            let storages = this.state.stockageCat;
            let storageStats = [];
            let totalStorage = 0
            for (let storage of storages) {
                let size = 0;
                for (let mimetype of storage.mimetypes) {
                    if (typeof result[mimetype] !== 'undefined') {
                        size = size + result[mimetype];
                        totalStorage = totalStorage + result[mimetype]
                    }
                }

                storageStats.push({
                    ...storage,
                    size: size,
                    show: true
                })

            }
            this.setState({
                stockageSize: totalStorage + result.freeSpace,
                stockageCat: storageStats
            }, () => {
                this.handleGetStorageSize();
            })
        })
    }

    handleGetStorageSize() {
        let globalStockage = this.state.stockageSize;
        let files = this.state.stockageCat;
        let newList = [];
        let remainginSize = this.state.stockageSize;
        for (let file of files) {
            let usedStockage = (100 * file.size) / globalStockage;
            file['graphSize'] = usedStockage;
            newList.push(file);
            remainginSize = remainginSize - file.size;
        }
        let warning = ((remainginSize / globalStockage) <= 0.1) ? true : false;
        remainginSize = Math.round(remainginSize * 100 + Number.EPSILON) / 100;
        this.setState({
            stockageCat: newList,
            remainginSize: remainginSize,
            warningStorage: warning
        }, () => {
            this.initPageInfo(this.props.typeSettings);
            this.initCards(this.props.typeSettings);
        });
    }

    render() {
        const { t } = this.props;
        console.log('this.state.stockageCat', this.state.stockageCat)
        console.log('this.state.remainginSize', this.state.remainginSize)
        return (
            <div>
                <TopPanel
                    icomoon="ico_parametre"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={t('drawer.configuration')}
                    // subtitle={this.props.t('settings.tax.subTitle')} 
                    // handlerAdd={!this.state.loading ? this.handleAddTax : null} 
                    // gradientColor1={colors.menu.regular} 
                    // gradientColor2={colors.menu.darker} 
                    // buttonAvailable={true}
                    hasBorder={true}
                    windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justifyContent="center" spacing={0}>
                    <Grid container style={{ padding: '0px 32px 32px 32px' }}>
                        <Grid container xs={6}>
                            <Grid item xs={8}>
                                <OurTypography variant="h2" fontweighttext={700} style={{ marginTop: '7px', fontSize: '25px' }}>{t(this.state.pageInfoSettings?.title)}</OurTypography>
                                <OurTypography variant="body2" colortext={colors.black.lighter} style={{ marginTop: '16px', lineHeight: '20px', fontSize: '16px' }}>{t(this.state.pageInfoSettings?.description)}</OurTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box style={{
                                    backgroundImage: `url(${this.state.pageInfoSettings?.image})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    transform: 'scale(1.5)',
                                    width: '100%',
                                    height: '100%',
                                }}></Box>
                            </Grid>
                        </Grid>
                        <Grid container xs={6} alignItems='center'>
                            <Grid container justifyContent="space-between" alignItems="center">
                                {
                                    this.state.pageInfoSettings?.datas.map((data, index) => {
                                        return (
                                            <Grid item xs={6} key={`LastDatas${index}`}>

                                                <Grid container justifyContent="center" alignItems="center" style={{
                                                    height: "100%",
                                                    flexDirection: this.props.windowWidth > 1200 ? 'row' : 'column'
                                                }}>
                                                    {/* Stats */}
                                                    <Grid xs={12} item style={{ maxWidth: 80, width: "100%" }}>
                                                        <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={data.color ?? colors.blue.darker.hue300} width="100%">
                                                            <CircularProgress variant="determinate" thickness={2.5} value={data.value} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2, color: data.color ?? colors.blue.darker.hue300 }} />
                                                            <CircularProgress
                                                                variant="determinate"
                                                                value={100}
                                                                disableShrink
                                                                style={{ width: "100%", height: "auto", color: data.color ?? colors.blue.darker.hue300 }}
                                                                thickness={2.5}
                                                            />
                                                            <Box
                                                                top={0}
                                                                left={0}
                                                                bottom={0}
                                                                right={0}
                                                                position="absolute"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <IcomoonReact iconSet={iconSet} color={data.color ?? colors.blue.darker.hue300} size={this.props.windowWidth > 1200 ? 35 : 25} icon={data.icon} />
                                                                {
                                                                    !data.icon ? (
                                                                        <Typography variant="body1" color="textSecondary" style={{ fontWeight: "bold", fontSize: 14 }}>
                                                                            {data.value}
                                                                        </Typography>
                                                                    ) : null
                                                                }
                                                            </Box>
                                                        </BoxCustom>
                                                    </Grid>
                                                    <Grid xs={12} item style={{
                                                        flex: 1,
                                                        paddingLeft: this.props.windowWidth > 1200 ? 22 : 0,
                                                        marginTop: this.props.windowWidth <= 1200 && 6
                                                    }}>
                                                        {/* Texts */}
                                                        <Grid container direction="column" alignItems="center" style={{
                                                            textAlign: this.props.windowWidth > 1200 ? 'start' : 'center'
                                                        }}>
                                                            <TitleBis variant="body1">{this.props.t(data.title)}</TitleBis>
                                                            <SubTitleBis variant="h4">{this.props.t(data.subtitle)}</SubTitleBis>
                                                            <MoreInfosBis variant="body2">{this.props.t(data.description)} <strong>{data.descriptionData}</strong></MoreInfosBis>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <AccordionCustom title='Actions' forcedExpanded={true}>
                        <Box style={{ flexGrow: 1 }}>
                            <Grid container direction="row" spacing={2}>
                                {
                                    !this.state.loading && this.state.listSettings.length > 0 ?
                                        this.state.listSettings.map((setting, index) => {
                                            return (
                                                <GridCustom item xl={4} lg={4} md={4} xs={6} key={`SettingTile-${index}`} onClick={() => this.goTo(setting.route)} style={{ cursor: 'pointer' }}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'20px 20px 20px 20px'}>
                                                        <Box style={{ height: '100%' }}>
                                                            <Grid item style={{ height: '100%' }}>
                                                                <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
                                                                    <Box>
                                                                        <Grid container alignContent='center'>
                                                                            <IconWrapper item>
                                                                                <IcomoonReact iconSet={iconSet} size={24} icon={setting.icone} color={colors.white} />
                                                                            </IconWrapper>
                                                                            <OurTypography variant="h2" fontweighttext={700} style={{ marginTop: '7px', lineheight: 'unset', fontSize: '25px' }}>{t(setting.name)}</OurTypography>
                                                                        </Grid>
                                                                        <Grid container>
                                                                            <OurTypography variant="body" style={{ lineheight: 'unset', fontSize: '16px', margin: '13px 0px 34px 0px' }}>{t(setting.description)}</OurTypography>
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box>
                                                                        <Button
                                                                            text={'Configurer'}
                                                                            onClick={() => this.goTo(setting.route)}
                                                                            style={{
                                                                                margin: '0px',
                                                                                minWidth: '212px',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                        :
                                        (<PageLoader />)
                                }
                            </Grid>
                        </Box>
                    </AccordionCustom>
                </Grid>
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}

export default withTranslation()(withApollo(withRouter(SettingsTemplate)));