import React from 'react';

export const htmlBuildGameEmail = (datas, spread) => {
    const STYLE = `
        <style type="text/css">
            tr, td, table, tbody{
                border-collapse: collapse;
                border-spacing: 0;
                padding: 0;
            }
            img{
                display:block;
            }
            h1{
                color: #2B4553;
                line-height: 36px;
                font-size: 28px;
            }
            .name_product{
                font-family:Arial, Helvetica, sans-serif;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                margin-top: 20px;
            }
            #footer a{
                color: white;
            }
            @media screen and (max-width: 520px){
                #containerBottom td{
                    font-size: 25px !important;
                    line-height: 32px !important;
                }
                #containerBottom td a{
                    width: 213px !important;
                    font-size: 21px !important;
                }
            }
            @media screen and (max-width: 375px){
                .message_felicitation{
                    font-size: 18px !important;
                    line-height: 22px !important;
                }
                #containerBottom td{
                    font-size: 22px !important;
                    line-height: 31px !important;
                }
                #containerBottom td a{
                    width: 213px !important;
                    font-size: 21px !important;
                }
                #footer td{
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        </style>
    `;

    const START = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html lang="fr" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <!--[if gte mso 9]><xml>
        <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml><![endif]-->
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${STYLE}
        </head>
        <body>
    `;
    
    const END = `
        </body>
        </html>
    `;

    console.log(datas?.blocks.title.inputs.value?.value ? true:false)

    return `
        ${!spread ? START : ''}
        ${spread ? STYLE : ''}
        <center>
            <table width="600" style="border-collapse: collapse;width: 600px" cellpadding="0" cellspacing="0">
                <tr>
                    <td 
                        background="${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'}" 
                        bgcolor="${datas?.inputs?.backgroundColor.value}" 
                        valign="top" 
                        style="background-color: ${datas?.inputs.backgroundColor.value}; background-image: ${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'};background-size:cover;"
                    >
                        <table id="container1" width="600">
                            <tr width="600">
                                <td width="600" style="height: 30px; font-size: ${datas?.blocks.header.inputs.size?.value}px; text-align: ${datas?.blocks.header.inputs.textAlign?.value}; text-transform: ${datas?.blocks.header.inputs.textTransform?.value}; background-color: ${datas?.blocks.header.inputs.backgroundColor?.value}; color: ${datas?.blocks.header.inputs.color?.value};">
                                    ${datas?.blocks.header.inputs.value?.value}
                                </td>
                            </tr>
                            <tr width="600">
                                <td width="600">
                                    <table width="600">
                                        <tr width="600">
                                            <td width="600" style="text-align: center;">
                                                <img src=${typeof datas.blocks?.imageTop.inputs.value.value === 'string' ? datas.blocks?.imageTop.inputs.value.value : datas.blocks?.imageTop.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imageTop.inputs.value.value.filePath}` : '/img/not-found.png'} style="width: 600px;" />
                                            </td>
                                        </tr>
                                        ${
                                            datas?.inputs?.isVirtual?.value === true ? `
                                                <tr width="600">
                                                    <td width="600" style="text-align: center;">
                                                        <img style="width: 600px;" src="%p1_image_header%" alt="Image dotation header"  />
                                                    </td>
                                                </tr>
                                            ` : ''
                                        }
                                        ${
                                            datas?.blocks.title.inputs.value?.value ?
                                            `<tr width="600">
                                            <td width="600" style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.title.inputs.textAlign?.value}; color: ${datas.blocks?.title.inputs.color?.value}; background-color: ${datas.blocks?.title.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.title.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.title.inputs.textTransform?.value}; font-weight: bold; padding-top: 40px; padding-left: 50px; padding-right: 50px;">
                                                ${datas?.blocks.title.inputs.value?.value}
                                            </td>
                                            </tr>`
                                            :``
                                        }
                                        ${
                                            datas?.blocks.subtitle.inputs.value?.value ?
                                            `<tr width="600">
                                                <td 
                                                    width="600" 
                                                    class="message_felicitation" 
                                                    style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.subtitle.inputs.textAlign?.value}; color: ${datas.blocks?.subtitle.inputs.color?.value}; background-color: ${datas.blocks?.subtitle.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.subtitle.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.subtitle.inputs.textTransform?.value}; font-weight: bold;padding-left: 50px; padding-right: 50px;padding-top: 16px;"
                                                >
                                                    ${datas?.blocks.subtitle.inputs.value?.value}
                                                </td>
                                            </tr>`
                                            :``
                                        }                                                                            
                                        ${
                                            datas?.inputs?.isWin?.value === true ? `
                                                <tr>
                                                    <td style="text-align: center;">
                                                        <img src="%p1_image%" alt="Image dotation" width="300"  style="display: block; margin: 0 auto; max-height: 300px; padding-top: 16px;object-fit:contain;">
                                                    </td>
                                                </tr>
                                                <tr width="600">
                                                    <td width="600" class="name_product" style="padding-top: 16px;color: black;text-decoration: none;">
                                                        <p style="font-size:16px;color:${datas.blocks?.description.inputs.color.value};">%p1_name%</p>
                                                    </td>
                                                </tr>
                                            ` : ''
                                        }
                                        ${
                                            datas?.inputs?.isVirtual?.value === true ? `
                                                <tr width="600">
                                                    <td width="600" class="name_product" style="padding-top: 16px;color: black;text-decoration: none;">
                                                        %p1_text1%
                                                    </td>
                                                </tr>
                                                <tr width="600">
                                                    <td width="600" class="name_product" style="padding-top: 16px;color: black;text-decoration: none;">
                                                        %p1_text2%
                                                    </td>
                                                </tr>
                                                <tr width="600">
                                                    <td width="600" height="32"></td>
                                                </tr>   
                                            ` : ''
                                        }      
                                        ${
                                            datas?.inputs?.isCode?.value === true ? `
                                                <tr width="600">
                                                    <td width="600" class="name_product" style="font-size:30px;font-family:'Cera_Black', sans-serif;padding-top: 16px;color: #DC1F2B;text-decoration: none;">
                                                        %code%
                                                    </td>
                                                </tr>
                                                <tr width="600">
                                                    <td width="600" height="32"></td>
                                                </tr>   
                                            ` : ''
                                        }                                      
                                        ${
                                            datas?.blocks.link.inputs.link.value ? 
                                            `
                                            <tr width="600">
                                                <td width="600" height="32"></td>
                                            </tr>
                                            <tr width="600">
                                                <td width="600">
                                                    <table>
                                                        <tr>
                                                            <td width="100"></td>
                                                            <td width="400">
                                                                <a href="${datas?.blocks.link.inputs.link.value}" target="_blank" rel="noreferrer noopener" style="width:100%; height: ${!datas?.blocks.link.inputs.backgroundImage.value ? '40px' : 'auto'}; display: block; text-decoration: none;font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.link.inputs.color.value};  background-color: ${datas.blocks?.link.inputs.backgroundColor.value}; text-align: center; font-size: ${datas.blocks?.link.inputs.size.value}px; line-height: 40px;">
                                                                    ${datas?.blocks.link.inputs.backgroundImage.value ? `
                                                                        <img src="${typeof datas?.blocks.link.inputs.backgroundImage.value.value === 'string' ? datas?.blocks.link.inputs.backgroundImage.value.value : datas?.blocks.link.inputs.backgroundImage.value?.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${datas?.blocks.link.inputs.backgroundImage.value.filePath}` : null}" />` : `<span style="height: 40px">${datas?.blocks.link.inputs.value.value}</span>`
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td width="100"></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr width="600">
                                                <td width="600" height="32"></td>
                                            </tr>
                                            `
                                            :``
                                        }                                                                            
                                        ${
                                            datas?.blocks.description.inputs.value?.value ?
                                            `  
                                            <tr width="600">
                                                <td 
                                                    class="message_felicitation" 
                                                    width="600" 
                                                    style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.description.inputs.color.value}; background-color: ${datas.blocks?.description.inputs.backgroundColor.value}; text-align: ${datas.blocks?.description.inputs.textAlign.value}; text-transform: ${datas.blocks?.description.inputs.textTransform.value}; font-size: ${datas.blocks?.description.inputs.size.value}px; line-height: 1.2; padding: 20px 50px;"
                                                >
                                                    ${datas?.blocks.description.inputs.value?.value}
                                                </td>
                                            </tr>
                                            `
                                            : ``
                                        }                                        
                                        ${
                                            datas.blocks?.imageBottom.inputs.value.value ? (`
                                                <tr width="600">
                                                    <td width="600" style="text-align: center;">
                                                        ${
                                                            datas?.blocks.linkBottom?.inputs.link.value?
                                                            `<a href="${datas?.blocks.linkBottom?.inputs.link.value}" target="_blank" rel="noreferrer noopener">
                                                                <img src=${typeof datas.blocks?.imageBottom.inputs.value.value === 'string' ? datas.blocks?.imageBottom.inputs.value.value : datas.blocks?.imageBottom.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imageBottom.inputs.value.value.filePath}` : ''} style="width: 600px;" />
                                                            </a>`
                                                            :`<img src=${typeof datas.blocks?.imageBottom.inputs.value.value === 'string' ? datas.blocks?.imageBottom.inputs.value.value : datas.blocks?.imageBottom.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imageBottom.inputs.value.value.filePath}` : ''} style="width: 600px;" />`
                                                        }                                                        
                                                    </td>
                                                </tr>
                                            `) : ''
                                        }
                                        ${
                                            datas.blocks?.imageBottom2.inputs.value.value ? (`
                                                <tr width="600">
                                                    <td width="600" style="text-align: center;">
                                                    ${
                                                        datas?.blocks.linkBottom2?.inputs.link.value ?
                                                        `<a href="${datas?.blocks.linkBottom2?.inputs.link.value}" target="_blank" rel="noreferrer noopener">
                                                            <img src=${typeof datas.blocks?.imageBottom2.inputs.value.value === 'string' ? datas.blocks?.imageBottom2.inputs.value.value : datas.blocks?.imageBottom2.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imageBottom2.inputs.value.value.filePath}` : ''} style="width: 600px;" />
                                                        </a>`
                                                        :`<img src=${typeof datas.blocks?.imageBottom2.inputs.value.value === 'string' ? datas.blocks?.imageBottom2.inputs.value.value : datas.blocks?.imageBottom2.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imageBottom2.inputs.value.value.filePath}` : ''} style="width: 600px;" />`
                                                    }                                                        
                                                    </td>
                                                </tr>
                                            `) : ''
                                        }
                                        <tr>
                                            <td>
                                                <!--[if gte mso 9]>
                                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width: 600px;">
                                                <v:fill type="frame" color="#040B28" />
                                                <v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0">
                                                <![endif]-->
                                                <table 
                                                    id="footer" 
                                                    width="100%" 
                                                    style="
                                                        background-color: ${datas.blocks?.footer.inputs.backgroundColor.value};
                                                        background-image: ${typeof datas.blocks?.footer.inputs.backgroundImage.value === 'string' ? `url(${datas.blocks?.footer.inputs.backgroundImage.value})` : datas.blocks?.footer.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas.blocks?.footer.inputs.backgroundImage.value.filePath})` : 'none'};
                                                        background-size: cover;
                                                    "
                                                >
                                                    <tr>
                                                        <td width="100%" style="text-align: justify;font-family:Arial, Helvetica, sans-serif; color: white; font-size: 16px; line-height: 23px;font-weight: 600;padding: 32px 20px;">
                                                            ${datas.blocks?.footerText.inputs.value.value}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!--[if gte mso 9]>
                                                </v:textbox>
                                                </v:rect>
                                                <![endif]-->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>
    ${!spread ? END : ''}
`;
};

function GameEmail({ data }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlBuildGameEmail(data, true) }} />
    )
}

export default GameEmail;
