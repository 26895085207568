import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../ui/button/Button';
import { Grid, Typography, Box } from '@material-ui/core';
import FormBuilder from '../FormBuilder';
import StepperCustom from '../../stepper/StepperCustom';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const Title = styled(Typography)`
  color: ${colors.black.regular};
  font-size: 34px;
`;

const Subtitle = styled(Typography)`
  color: ${colors.black.regular};
  max-width: 380px;
  margin: auto;
  font-weight: lighter;
`;

const RequiredMessage = styled(Typography)`
    color: ${colors.grey.regular};
    font-weight: lighter;
    font-style: italic;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions_section: {
    /* padding: "24px 32px", */
    /* padding: '15px 0px 15px 0px', */
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

function getSteps(getData) {
  return getData;
}

function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps(props.navFormConfig.children);
  const isStepOptional = step => {
    return (
      step.isOptionnal === true
    )
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let goNext = () => {
      let newSkipped = skipped;

      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    console.log(props)

    if (props.stepperButtonAction[activeStep] !== null) {
      let canGoNext = props.stepperButtonAction[activeStep]();
      if (typeof canGoNext === "boolean") {
        if (canGoNext) {
          goNext();
        }
      } else {
        goNext();
      }
    } else {
      goNext();
    }
  };

  const handleBack = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setSkipped(newSkipped);

    if (props.backStepperButtonAction) {
      if (props.backStepperButtonAction[activeStep] !== null) {
        props.backStepperButtonAction[activeStep]();
      }
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(steps[activeStep])) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    if (props.stepperSkip) {
      setActiveStep(prevActiveStep => props.stepperSkip);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }
  };

  const CustomComponent = activeStep !== steps.length && steps[activeStep].component ? steps[activeStep].component : null;
  let CustomComponentProps;
  if (CustomComponent) {
    CustomComponentProps = steps[activeStep].componentProps;
  }
  return (
    <div className={classes.root}>
      <StepperCustom className={steps.length <= 1 ? "onlyStep" : activeStep === steps.length ? "endSteps" : null} activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(step)) {
            if ((props.windowWidth > 1000 && steps.length <= 3) || (props.windowWidth > 1400 && steps.length > 3) || activeStep === index) {
              labelProps.optional = <Typography variant="caption">&nbsp;(Optionnel)</Typography>;
            }
          }
          if (isStepSkipped(index)) {
            activeStep === index ? stepProps.completed = false : stepProps.completed = true;
          }
          return (
            <Step className={activeStep === index ? 'active' : null} key={step.labelName} {...stepProps}>
              <StepLabel {...labelProps}>- {props.t(step.labelName)}</StepLabel>
            </Step>
          );
        })}
      </StepperCustom>
      <div style={{ /* border: `0.5px solid ${activeStep === steps.length ? colors.green.regular : colors.grey.lighter.hue700}`, */ /* borderTop: 0, */ maxHeight: activeStep === steps.length ? "60vh" : 'auto', minHeight: activeStep === steps.length ? "60vh" : 'auto', display: activeStep === steps.length ? 'flex' : 'block', alignItems: 'center', justifyContent: 'center', overflow: "auto", display: 'grid', gridTemplateRows: '1fr auto', gridTemplateColumns: '1fr' }}>
        {/* FINAL STEP */}
        {activeStep === steps.length ? (
          <Grid item xs={12}>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={3}>
                <Box textAlign={"center"}>
                  <CheckCircleOutlineIcon style={{ fill: colors.green.regular, width: "100%", height: "100%", maxHeight: "54px" }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign={"center"} mt={2}>
                  <Title variant={"h2"}>{props.navFormConfig.finalStep.title}</Title>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign={"center"} mt={1}>
                  <Subtitle variant={"h4"}>{props.navFormConfig.finalStep.subtitle}</Subtitle>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign={"center"} mt={4}>
                  <Button bgcolor={colors.green.regular} shadowcolor={colors.green.darker} text={props.navFormConfig.finalStep.textButton} onClick={props.handlerMutation} className="button" />
                </Box>
              </Grid>
              {/* <Grid item xs={12}>
                    <Box textAlign={"center"} mt={1}>
                        <Button 
                          onClick={handleBack} 
                          className={classes.button} 
                          text="Revenir"
                          bgcolor="transparent"
                          bgcolorhover="transparent"
                          color={colors.grey.regular}
                          disableElevation={true}
                          arrow="left"
                        />
                    </Box>
                  </Grid> */}
            </Grid>
          </Grid>

        ) : (
          <div style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr auto' }}>
            <Box style={{ overflow: "auto", display: 'grid' }} maxHeight={{ xs: 'calc(100% - 15px)', /* md: '66vh' */ md: '62vh' }} minHeight={{ xs: 'calc(100% - 15px)', /* md: '66vh' */ md: '62vh' }}>
              {
                CustomComponent
                  ?
                  <CustomComponent
                    stateCallback={props.stateCallback}
                    errorCallback={props.errorCallback}
                    allState={props.allState}
                    optionsInputs={steps[activeStep].optionsInputs}
                    paginationInfo={props.paginationInfo}
                    searchHandler={props.searchHandler}
                    isFormComponent={true}
                    customProps={CustomComponentProps}
                    stepsInfo={steps[activeStep]}
                  />
                  : <FormBuilder
                    styleInnerContainer={props.styleInnerContainer}
                    backgroundColor={props.backgroundColor}
                    optionsInputs={steps[activeStep].optionsInputs}
                    stateCallback={props.stateCallback}
                    errorCallback={props.errorCallback}
                    allState={props.allState}
                    handleButtonGroupChange={props.handleButtonGroupChange ? props.handleButtonGroupChange : null}
                    handleTreeChange={props.handleTreeChange}
                    expandNodes={props.expandNodes}
                    treeEditCat={props.treeEditCat}
                    treeDeleteCat={props.treeDeleteCat}
                    treeAddCat={props.treeAddCat}
                    handleChangeCategorie={props.handleChangeCategorie}
                    selectedCategorie={props.selectedCategorie}
                    productByCategorie={props.productByCategorie}
                    selectedProductByCategorie={props.selectedProductByCategorie}
                    setSelectedProducts={props.setSelectedProducts}
                    copyCategories={props.copyCategories}
                    allCategories={props.allCategories}
                    gridItems={props.gridItems}
                    loadGrid={props.loadGrid}
                    addProducts={props.addProducts}
                    merchModal={props.merchModal}
                    resetReloadListing={props.resetReloadListing}
                    hideInput={props.hideInput !== null ? props.hideInput : null}
                    paginationInfo={props.paginationInfo}
                    searchHandler={props.searchHandler}
                    useLocking={props.useLocking}
                  />
              }
            </Box>
            {/* Buttons */}
            {/* {console.log(props.windowWidth)} */}
            <Box className={classes.actions_section} p={{
              xs: '0px 0px 15px 0px',
              md: '0px 0px 0px 0px'
              /* md: '15px 0px 0px 0px' */
            }}>
              <Grid container direction="row" justifyContent="space-between" style={{
                padding: '15px 0px',
              }}>
                <Box>
                  <RequiredMessage variant="h4">*{props.t('products.obligate')}</RequiredMessage>
                </Box>
                <Box>
                  {activeStep !== 0 ?
                    <Button
                      disabled={activeStep === 0}
                      bgcolor="transparent"
                      bgcolorhover="transparent"
                      color={colors.grey.regular}
                      disableElevation={true}
                      onClick={handleBack}
                      className={classes.button}
                      text="Etape précédente"
                      arrow="left"
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    : ''}

                  {isStepOptional(steps[activeStep]) && (
                    <Button
                      variant="contained"
                      color="white"
                      bgcolor={colors.blue.darker.hue300}
                      onClick={handleSkip}
                      className={classes.button}
                      text="Passer"
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                  )}

                  <Button
                    variant="contained"
                    color="white"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={props.stepperButtonDisabled ? (props.stepperButtonDisabled[activeStep] !== null && props.stepperButtonDisabled[activeStep]()) : null}
                    text={activeStep === steps.length - 1 ? 'Etape suivante' : props.t('products.categories.importation.next')}
                    arrow="right"
                    style={{
                      marginRight: "0px",
                      margin: "0px",
                    }}
                  />
                </Box>
              </Grid>

            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
export default withTranslation()(HorizontalLinearStepper)