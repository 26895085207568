import React from 'react';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";
import request from '../../../../js/utils/fetch';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import _ from 'lodash';
import mapDispatchToProps from '../../../../js/helpers/mapDispatchToProps';
import mapStateToProps from '../../../../js/helpers/mapStateToProps';
import * as Pagination from '../../../../js/utils/pagination';
import * as moment from 'moment';
import 'moment-timezone';

import { eventService } from '../../../../js/services/event.service';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../js/utils/axios';
import { CopyWithJSON } from "../../../../js/utils/copy";
import { toggleExpandedForAll } from 'react-sortable-tree';

import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';

import { PRODUCTS_BY_CATALOGS_CAT_PAGINATION } from '../../../../queries/products';
import { DELETE_CATALOG, UPDATE_CATALOG, GET_CATALOG_TYPES, GET_CATALOGS_BY_ID, GET_CATALOGS_CATEGORIES_BY_ID, GET_CATALOGS_BY_ID_ATTRS, GET_CATALOGS_BY_ID_CAT_FILTERS, CATALOG_DETAILS_GET_CATALOGS_BY_ID, CATALOG_DETAILS_FILTER_GET_CATALOGS_BY_ID } from '../../../../queries/catalogs';
import { GET_ASSET_BY_CATALOG_ID, COUNT_ASSETS_BY_CATALOG_ID } from '../../../../queries/assets';
import { GET_CATEGORIES_LIGHT_2, GET_CATEGORIES_ONLY, DELETE_CATEGORY, UPDATE_CATEGORY_DATA, ADD_CATEGORY_DATA, DELETE_CATEGORY_DATA, UPDATE_CATEGORY, ADD_CATEGORY, UPDATE_CATEGORY_POSITION, GET_CATEGORIES_BY_ID_LIST_LIGHT } from '../../../../queries/categories';
import { CREATE_CATEGORY_FILTER, DELETE_CATEGORY_FILTER, UPDATE_CATEGORY_FILTER } from '../../../../queries/category_filter';
import { CREATE_CATALOG_FILTER, DELETE_CATALOG_FILTER, UPDATE_CATALOG_FILTER, GET_CATALOG_FILTERS } from '../../../../queries/catalog_filter';
import { GET_MAGENTO_PAGES_BY_ASSETS_LIST } from '../../../../queries/assetMagentoPage';

import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_CATALOGS_LIST } from '../../../../js/constants/route-names';

import formCategoriesAdd from '../products/config/formCategoriesAdd.config';
import formCategoriesEdit from '../products/config/formCategoriesEdit.config';

import catalogEditForm from './config/catalogEditForm.config';
import productSelectorConfig from './config/productSelector.config';
import catalogEditProductsCategories from './config/catalogEditProductsCategories.config';
import catalogEditSelectionsProducts from './config/catalogEditSelectionsProducts.config';
import catalogEditAttributs from './config/catalogEditAttributs.config';

import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import InputBuilder from '../../../ui/form/InputBuilder';
import DialogModal from '../../../ui/dialog/DialogModal';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import SearchBar from '../../../ui/search/SearchBar';
import CatalogHeader from './components/CatalogHeader';

import ModalSynchro from "./components/ModalSynchro";
import ListingProducts from '../products/components/ListingProducts';
import { getTraductionAttributs } from '../../../../js/utils/functions';

import ProductMerchandising from './components/ProductMerchandising';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_CONTENT_CATEGORIES_ONLY } from '../../../../queries/content_categories';
import Button from '../../../ui/button/Button';
import NoImage from '../../../../assets/images/image-fake-card.png';
import TextEllipsis from '../../../layouts/Card/cardContent/TextEllipsis';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const BoxCustom = styled(Box)`
    width: auto;
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 20px;
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;

const CircleStatus = styled.div`
    background-color: ${props => props.colorcircle ? colors.green.regular : process.env.REACT_APP_MODE_SPREAD === "hub" ? colors.red.regular : colors.grey.lighter.hue600};
    border-radius: 50%;
    width: 10px;
    height: 10px;
`;

const TypographyCustom = styled(Typography)`
    font-size: 14px;
    font-weight: bold;
    padding-left: 6px;
    line-height: 16px;
    color: ${props => props.colortext ? colors.green.regular : colors.grey.lighter.hue600} !important;
`;

const WrapperImage = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 270px;
    height: 100%;
`

class EngineCatalogsDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeCategorie: ["Home"],
            styleInnerContainer: {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            openForm: false,
            openFormCategories: false,

            openFormAttributs: false,
            openSelectionProduct: false,
            
            openDialogCategory: false,
            openDialogTreeView: false,
            openMerchModal: false,
            idCatalog: `/api/catalogs/${this.props.match.params.id}`,
            catalogData: null,
            assetMagentoPages: null,
            contentCategories: null,
            startDate: '',
            endDate: '',
            nbProducts: 0,
            imageCatalog: null,
            categories: [],
            filteredCat: [],
            errors: {},
            seeErrors: false,
            ready: false,
            expanded: {},
            productByCategorie: [],
            selectedProductByCategorie: [],
            selectedCategorie: '',
            drawerWidthModified: props.drawerWidth,
            currentLang: props.locales[0].node.code,
            allCategories: [],
            typeCategorie: 'category',
            pagination: {
                page: 0,
                perPage: 6,
                count: 0,
            },
            paginationCheckboxItem: {
                page: 0,
                perPage: 8,
                count: 0,
            },
            idlist: [],
            arrayCatFilter: [],
            noResult: false,
            anchorEl: null,
            searchModal: '',
            listAsset: [],
            countAssets: 0,
            modalSynchro: false,
            reloadTreeCategories: uuidv4(),
            reloadProductList: false,
            reloadProductFormList: false,
            catFilters: [],
            catalogFilters: [],
            changeInMerch: false,
            categoryMaster: null,
            catalogSelected: null,
            allCategoriesReady: false
        };
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        }
        else {
            this.handleDrawerWidthChange(-800);
        }
    };

    handleDrawerWidthChange = (width) => {
        this.setState({
            drawerWidthModified: width
        });
    };

    handleToggleDialog = (stateName) => {
        if (stateName === "openMerchModal") {
            this.setState({
                changeInMerch: false,
            })
        }
        this.setState({ [stateName]: !this.state[stateName] });
    };

    getAssetMagentoPages = () => {
        let assetList = []

        for (let asset of this.state.catalogData.assets.edges) {
            assetList.push(asset.node.id);
        }

        this.props.client.query({
            query: GET_MAGENTO_PAGES_BY_ASSETS_LIST,
            variables: { assetList, useInCategory: true },
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({ assetMagentoPages: result.data.assetMagentoPages.edges });
        })
    }

    getContentCategories = () => {
        this.props.client.query({
            query: GET_CONTENT_CATEGORIES_ONLY,
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({ contentCategories: result.data.contentCategories });
        })
    }

    getCatalog = async (avoidLoadingCats) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({ pagination });

        // GET CATALOGS
        /* TODO: Donovan -> Mettre l'info dans le local storage */
        let localStorageCatalog = localStorage.getItem('catalog_' + this.state.idCatalog);
        if (!localStorageCatalog) {
            this.props.client.query({
                query: CATALOG_DETAILS_GET_CATALOGS_BY_ID,
                variables: { id: this.state.idCatalog },
                fetchPolicy: 'no-cache',
            }).then(result => {
                let findCatalog = result.data.catalog;
                this.getCatalogResult(findCatalog, avoidLoadingCats);
            });
        } else {
            let result = JSON.parse(localStorageCatalog);
            this.getCatalogResult(result, avoidLoadingCats);
        }
    };


    getCatalogResult = (findCatalog, avoidLoadingCats) => {
        let local = moment.tz.guess();
        let toDateStart = moment.tz(findCatalog.startAt, local).format('L');
        let toDateStartNotConverted = moment(findCatalog.startAt).format('YYYY-MM-DD');
        let toDateEnd = moment.tz(findCatalog.endAt, local).format('L');
        let toDateEndNotConverted = moment(findCatalog.endAt).format('YYYY-MM-DD');
        this.setState({
            autosync: findCatalog.autosync,
            libelle: findCatalog.libelle,
            //categories: findCatalog.categories,
            description: findCatalog.description,
            catalogId: findCatalog.id,
            catalogIdentifier: findCatalog.identifier,
            status: findCatalog.status,
            imageCatalog: findCatalog.media,
            catalogData: findCatalog,
            startDateNotConverted: toDateStartNotConverted,
            startDate: toDateStart,
            endDateNotConverted: toDateEndNotConverted,
            endDate: toDateEnd,
            ready: true,
        }, () => {
            // Now in initCatalogFilters
            // this.prepareSecondTree(this.state.categories, true);
            // this.getAssetMagentoPages();
        });

        // GET PRODUCTS
        let filteredProducts = [];
        let idlist = [];
        for (let category of this.state.categories) {
            for (let productCat of category.productCategories.edges) {
                if (filteredProducts.find(value => value.node.id === productCat.node.id)) {
                } else {
                    idlist.push(productCat.node.product.id.replace('/api/products/', ''));
                    filteredProducts.push(productCat);
                }
            }
        }

        this.setState({
            idlist,
            filteredCat: filteredProducts,
        });

        this.getAssetByCatalogId();

        if (!avoidLoadingCats)
            this.initCatalogFilters();

        this.asyncGetCatalogAttributes();
        this.initAttributs();
        this.getContentCategories();
    }


    initCatalogFilters = (reload) => {
        if (reload) {
            this.props.startLoading()
        }
        this.props.client.query({
            query: CATALOG_DETAILS_FILTER_GET_CATALOGS_BY_ID,
            variables: { id: this.state.idCatalog },
            fetchPolicy: 'no-cache',
        }).then(result => {
            let catalogFilters = [];
            let values = null;

            let findCatalog = result.data.catalog;

            if (result.data.catalog.catalogFilters.edges.length > 0) {
                for (let filter of result.data.catalog.catalogFilters.edges) {
                    if (filter.node.attribute.attributeType.input === "select") {
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing.map(e => `/api/attribute-options/${e}`)
                        } else {
                            values = filter.node.attributeOption.id
                        }
                    } else {
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN") {
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing
                        } else {
                            values = filter.node.value
                        }
                    }
                    catalogFilters.push({
                        new: false,
                        changed: false,
                        code: filter.node.attribute.id,
                        values,
                        operator: filter.node.operator,
                        attributeType: { node: filter.node.attribute },
                        id: filter.node.id,
                        checkbox: false,
                        typingChips: null,
                        showButton: false,
                    })
                }
            }
            this.setState({ catalogFilters })

            for (let category of findCatalog.categories) {
                category.productCategories = {
                    totalCount: 0,
                    edges: []
                };
            }
            let allCategories = this.state.allCategories.concat(findCatalog.categories);
            this.setState({ allCategories, categories: findCatalog.categories, allCategoriesReady: true }, () => {

                this.prepareSecondTree(this.state.categories, true);
                this.getAssetMagentoPages();
            })
            if (reload) {
                this.props.stopLoading()
            }
        });
    };

    asyncGetCatalogAttributes = () => {
        this.props.client.query({
            query: GET_CATALOGS_BY_ID_ATTRS,
            variables: { id: this.state.idCatalog },
            fetchPolicy: 'no-cache',
        }).then(result => {
            let findCatalog = result.data.catalog;

            this.setState({
                attributesSelected: findCatalog.attributes?.edges.map(e => e.node.id) ?? [],
                ready: true,
            });
        });
    };

    getAssetByCatalogId = () => {
        this.props.client.query({
            query: COUNT_ASSETS_BY_CATALOG_ID,
            variables: { catalog: this.state.idCatalog },
            //fetchPolicy: 'no-cache',
        }).then(result => {
            // let listAsset = [];
            // if(result.data.assets.edges.length>0){
            //     for(let asset of result.data.assets.edges){
            //         listAsset.push(asset)
            //     }
            // }
            let listAsset = [];
            let countAssets = result.data.assets.totalCount;
            this.setState({ listAsset });
            this.setState({ countAssets });
        });
    };

    getFiltersCatalogs = () => {
        this.props.client.query({
            query: GET_CATALOG_FILTERS,
            fetchPolicy: 'no-cache',
        }).then(result => {
            let filtersList = result.data.catalogFilters;
        });
    };

    handleGetCatalogsTypes = () => {

        let localStorageAllCatalogsTypes = localStorage.getItem('allCatalogsTypes');
        if (!localStorageAllCatalogsTypes) {
            this.props.client.query({
                query: GET_CATALOG_TYPES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                let allCatalogsTypes = result.data.catalogTypes.edges;
                localStorage.setItem('allCatalogsTypes', JSON.stringify(allCatalogsTypes));

                this.setState({ allCatalogsTypes })
            })
        } else {
            let allCatalogsTypes = JSON.parse(localStorageAllCatalogsTypes);
            this.setState({ allCatalogsTypes })
        }

    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
        this.prepareSecondTree(this.state.categories)
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (stateName === "catalogType") {
            let getNewType = this.state.allCatalogsTypes.find(e => e.node.id === value)
            this.setState({
                [stateName]: value,
                type: getNewType.node
            })
        }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        if (custom) {
            if (custom.props) {
                this.setState({ [stateName]: custom.props.value });
            } else {
                this.setState({ ...custom });
            }
        }
        else {
            const value = evt?.target?.value ?? evt;
            this.setValue(stateName, value, translated);
        }
    };

    handleMediaPicker = (selected, stateName) => {
        if (stateName === 'imageCatalog') {
            this.handleInputChange(stateName, selected, null, false);
        }
        else {
            this.handleInputChange(stateName, selected, null, true);
        }
    };

    setExpanded = (all, node) => {
        if (node) {
            all[node.id] = node.expanded;

            for (let child of node.children) {
                this.setExpanded(all, child);
            }
        }
    };

    storeDraggedCats = (categoriesToAdd, category, parent) => {
        let foundCat = this.state.categories.find(e => e.id === category.id);

        if (!foundCat) {
            // set data format
            category = CopyWithJSON(category);
            category.fromLeft = true;
            category.libelle = `${category.libelle}_${this.state.catalogIdentifier}`;

            let attributes = [];

            for (let { node } of category.categoryDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }

            category.attributes = attributes;

            categoriesToAdd.push(category);
        }

        // set correct parent
        category.parent = parent ? {
            id: parent
        } : null;

        for (let child of category.children) {
            this.storeDraggedCats(categoriesToAdd, child, category.id);
        }
    };

    modifyCategoryAttributes = category => {
        for (let attribute of this.props.attributes.category.attributes.edges) {
            let isMedia = attribute.node.attributeType.input === 'image';

            for (let locale of this.props.locales) {
                let value = this.state[locale.node.code][attribute.node.identifier];
                let catAttribute = category.attributes?.find(e => e.id === attribute.node.id);

                if (catAttribute) {
                    let catAttributeLocale = catAttribute.locales.find(e => e.code === locale.node.code);

                    if (catAttributeLocale) {
                        catAttributeLocale.value = isMedia ? null : value;
                        catAttributeLocale.media = isMedia ? {
                            ...catAttributeLocale.media,
                            ...value
                        } : null;
                    } else if (value) {
                        catAttribute.locales.push({
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        });
                    }
                } else if (value) {
                    category.attributes.push({
                        id: attribute.node.id,
                        identifier: attribute.node.identifier,
                        attributeType: attribute.node.attributeType,
                        locales: [{
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        }]
                    });
                }
            }
        }
        return category;
    };

    modifyCategoryFilters = category => {
        for (let filter of this.state.catFilters) {
            if (filter.new) {
                category.filters.push(filter)
            } else if (!filter.new && (filter.changed || filter.deleted)) {
                let getCatFilters = category.filters.find(e => e.id === filter.id)
                getCatFilters = filter
            }
        }
        return category
    };

    handleMutationCategories = async () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }
        this.props.startLoading();
        this.setState({ idCatSave: this.state.cat_id });

        switch (this.state.editForm) {
            case 'edit':
                for (let editCat of this.state.categories) {
                    if (editCat.id === this.state.cat_id) {
                        editCat.status = this.state.catActivated
                        editCat.edited = true;
                        editCat.id = this.state.currentNode.id;
                        editCat.libelle = this.state.identifier;
                        editCat.parent = { id: this.state.parent };

                        if (this.state.typeCategorie === 'cms' && this.state.cmsPage) {
                            editCat.cmsPage = this.state.cmsPage;
                        }

                        if (this.state.typeCategorie === 'content' && this.state.categoryContent) {
                            editCat.contentCategory = this.state.categoryContent;
                        }

                        await this.modifyCategoryFilters(editCat);
                        this.modifyCategoryAttributes(editCat);

                        this.setState({
                            categories: this.state.categories
                        }, () => {
                            this.prepareSecondTree(this.state.categories, false, true, true);
                        });
                    }
                }

                break;
            case 'add':
                let newCat = {
                    new: true,
                    attributes: [],
                    categoryDatas: { edges: [] },
                    libelle: this.state.identifier,
                    parent: { id: this.state.parent },
                    products: { edges: [] },
                    status: this.state.catActivated,
                    id: uuidv4(),
                };


                let variables = {
                    libelle: this.state.identifier,
                    parent: this.state.parent,
                    catalog: this.state.idCatalog,
                    master: this.state.categoryMaster,
                    status: this.state.catActivated,
                    type: this.state.typeCategorie,
                };

                if (this.state.typeCategorie === 'cms') {
                    variables.cmsPage = this.state.cmsPage;
                }

                if (this.state.typeCategorie === 'content') {
                    variables.contentCategory = this.state.categoryContent;
                }

                let MUTATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_CATEGORY,
                    variables
                });

                newCat.id = MUTATION_RESULT.data.createCategory.category.id;
                newCat.new = false;

                variables = {
                    id: newCat.id,
                    position:newCat.id.replace('/api/categories/', '')
                };
                await this.props.client.mutate({
                    mutation: UPDATE_CATEGORY,
                    variables
                });

                newCat = await this.modifyCategoryAttributes(newCat);
                await this.handleSaveFilters(newCat)
                await this.saveAttributes(newCat, newCat);

                let temporaryCategories = this.state.categories;
                temporaryCategories.push(newCat);
                this.setState({
                    // idCatSave: newCat.id,
                    categories: temporaryCategories,
                }, async () => {
                    this.prepareSecondTree(this.state.categories, false, false);
                });
                break;
            default: return;
        }
        this.prepareFirstTree(true);
        this.handleToggleDrawer('openFormCategories', false);
    };

    processCategory = (category, parent = null, allCategories) => {
        return new Promise(async (resolve, reject) => {
            if (category.id === this.state.idCatSave) {
                let query = null;
                let variables = null;

                if (category.new || category.fromLeft) {
                    query = ADD_CATEGORY;
                    variables = {
                        libelle: category.libelle,
                        parent,
                        catalog: this.state.idCatalog,
                        master: category.fromLeft ? category.id : this.state.categoryMaster,
                        status: category.status,
                    };

                    let products = this.state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                } else {
                    query = UPDATE_CATEGORY;
                    variables = {
                        id: category.id,
                        libelle: category.libelle,
                        parent: this.state.parent,
                        status: category.status,
                        master: this.state.categoryMaster
                    };

                    if (this.state.typeCategorie) {
                        variables.type = this.state.typeCategorie;
                    }
                    if (this.state.cmsPage) {
                        variables.cmsPage = this.state.cmsPage;
                    }

                    if (this.state.typeCategorie === 'content') {
                        variables.contentCategory = this.state.categoryContent;
                    }

                    let products = this.state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                }

                const MUTATION_RESULT = await this.props.client.mutate({
                    mutation: query,
                    variables
                });

                await this.handleSaveFilters(category)

                let uptodate = !category.new && !category.fromLeft
                    ? MUTATION_RESULT.data.updateCategory.category
                    : MUTATION_RESULT.data.createCategory.category;

                await this.saveAttributes(category, uptodate);

                allCategories.push(uptodate.id);

                for (let child of category.children) {
                    await this.processCategory(child, uptodate.id, allCategories);
                }
            } else {
                if (category.new) { }
                for (let child of category.children) {
                    await this.processCategory(child, category.id, allCategories);
                }
            }
            resolve();
        });
    };

    handleSaveFilters = async (category) => {
        let queryFilter = null;
        let variablesFilter = null;
        for (let filter of this.state.catFilters) {
            let value = null;
            let attributeOption = null;
            if (filter.attributeType.node.attributeType.input === "select") {
                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                    let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                    value = JSON.stringify(arrayRegex)
                } else {
                    attributeOption = filter.values
                }
            } else {
                if (filter.operator === "IN" || filter.operator === "NOT IN") {
                    value = JSON.stringify(filter.values)
                } else {
                    value = filter.values
                }
            }
            if (filter.new) {
                queryFilter = CREATE_CATEGORY_FILTER;
                variablesFilter = {
                    operator: filter.operator,
                    createdAt: moment(),
                    category: category.id,
                    attribute: filter.code,
                    attributeOption,
                    value
                }
            } else if (filter.changed && !filter.deleted) {
                queryFilter = UPDATE_CATEGORY_FILTER;
                variablesFilter = {
                    id: filter.id,
                    operator: filter.operator,
                    category: category.id,
                    attribute: filter.code,
                    attributeOption,
                    value
                }
            } else if (filter.deleted) {
                queryFilter = DELETE_CATEGORY_FILTER;
                variablesFilter = {
                    id: filter.id,
                }
            }
            if (queryFilter && variablesFilter) {
                await this.props.client.mutate({
                    mutation: queryFilter,
                    variables: variablesFilter
                });
            }
        }
    }

    resetState() {
        this.setState({
            identifier: '',
            parent: '',
            errors: {},
            seeErrors: false,
            categoryMaster: null,
            catalogSelected: null,
            catFilters: [],
            typeCategorie: 'category',
            contentCategory: null
        });
    }

    handleCancel = () => {
        this.getCatalog();
    };

    handleRecursiveCat = (breadcrumb) => {
        this.state.activeCategorie.push('Home');
        for (let categorie of breadcrumb) {
            let translatedLibelle = categorie.categoryDatas.find(e => e.locale.code === this.state.currentLang)
            if (translatedLibelle) {
                this.state.activeCategorie.push(` > ${translatedLibelle.value}`);
            } else {
                this.state.activeCategorie.push(` > ${categorie.libelle}`);
            }
        }
    };

    getChildrenCat = (id) => {
        for (let categorie of this.state.categoriesShowed) {
            if (categorie.id === id) {
                for (let child of categorie?.childrens) {
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    deleteMutationCategory = () => {
        this.props.startLoading();
        if (!this.state.currentNode.new && !this.state.currentNode.fromLeft) {
            this.props.client.mutate({
                mutation: DELETE_CATEGORY,
                variables: { id: this.state.cat_id }
            }).then(() => {
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

                this.removeNode(this.state.cat_id);
                this.handleToggleDrawer('openFormCategories', false);
                this.handleToggleDialog('openDialogCategory');
                this.resetState();
            }).catch(error => {
                this.props.stopLoading();
                this.props.snack(ALERT_ERROR, `Impossible de supprimer la catégorie, veuillez vérifier qu'elle n'est pas utilisée`);
                this.handleToggleDialog('openDialogCategory');
            });
        } else {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

            this.removeNode(this.state.cat_id);
            this.handleToggleDrawer('openFormCategories', false);
            this.handleToggleDialog('openDialogCategory');
            this.resetState();
        }
    };

    removeNode = (id) => {
        let allCategories = this.state.categories;
        allCategories = this.state.categories.filter(e => e.id !== id);

        this.setState({
            categories: allCategories
        }, () => this.prepareSecondTree(this.state.categories));
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    // SAVE TREE
    copyCategories = (nodeInfo) => {
        this.setState({ openDialogTreeView: true, copyCategories: nodeInfo });
    };

    selectCategorie = (nodeInfo) => {
        this.setState({ selectCategorie: nodeInfo });
    };

    validateNewCategorie = () => {

        this.props.startLoading();
        let data = {};
        data.idCategory = parseInt(this.state.copyCategories.node.id.replace('/api/categories/', ''));
        data.idParent = parseInt(this.state.selectCategorie.node.id.replace('/api/categories/', ''));

        request(`${process.env.REACT_APP_API}/categories/copy`, 'post', data).then(response => {
            if (response.success) {
                this.props.client.query({
                    query: GET_CATALOGS_CATEGORIES_BY_ID,
                    variables: { id: this.state.idCatalog },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    let findCatalog = result.data.catalog;
                    let allCategories = this.state.categoriesSpread.concat(findCatalog.categories)
                    this.setState({
                        categories: findCatalog.categories,
                        openDialogTreeView: false,
                        copyCategories: null,
                        selectCategorie: null,
                        allCategories
                    }, () => {
                        this.prepareSecondTree(this.state.categories, true);
                        this.props.stopLoading();
                    });
                });
            }
            else {
                this.props.stopLoading();
            }
        });
    };

    addSubcategory = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({
            openFormCategories: true,
            editForm: 'add',
            parent: nodeInfo.id,
            catActivated: true,
        });
    };

    deleteCat = (id)=>{
        this.props.startLoading()
        this.props.client.mutate({
            mutation: DELETE_CATEGORY,
            variables: { id: id},
        }).then(()=>{
            this.initCatalogFilters()
            // this.props.stopLoading()
            this.props.snack(ALERT_SUCCESS, 'La catégorie a été supprimé');
        }).catch((err)=>{
            console.log(err)
            this.props.snack(ALERT_ERROR, 'Une erreur est survenue pendant la suppression');
        })
    }

    editCat = async (nodeInfo) => {
        this.resetState();
        this.props.startLoading();
        let result = await this.props.client.query({
            query: GET_CATALOGS_BY_ID_CAT_FILTERS,
            variables: { id: this.state.idCatalog, categoryId: nodeInfo.id.replace('/api/categories/', '') },
            fetchPolicy: 'no-cache',
        });

        let catalogSelected = null;

        if (nodeInfo.master?.id) {
            let result2 = await this.props.client.query({
                query: GET_CATEGORIES_BY_ID_LIST_LIGHT,
                variables: { idList: [nodeInfo.master.id.replace('/api/categories/', '')] },
                fetchPolicy: 'no-cache',
            });
            catalogSelected = result2.data.categories[0].catalog.id
        }

        this.props.stopLoading();

        let findCategory = result.data.catalog?.categories?.[0];
        let categoryFilters = findCategory?.categoryFilters?.edges;

        let filters = [];

        if (categoryFilters && categoryFilters.length > 0) {
            for (let filter of categoryFilters) {
                if (filter) {
                    let getFilterData = {
                        ...filter.node,
                        attributeType: { node: filter.node.attribute }
                    }

                    filters.push(getFilterData);
                }
            }
        }

        nodeInfo.filters = filters;

        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of nodeInfo.attributes) {
                for (let attributeLocale of attribute.locales) {
                    if (attributeLocale.code === locale.node.code) {
                        switch (attribute.attributeType.input) {
                            case 'image':
                                values[attribute.identifier] = {
                                    data: attributeLocale.media ? attributeLocale.media.data : null,
                                    file: attributeLocale.media ? attributeLocale.media.file : null,
                                    changed: attributeLocale.media ? attributeLocale.media.changed : null
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;
                        }
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
        let catFilters = [];
        let values = null;
        if (nodeInfo.filters.length > 0) {
            for (let filter of nodeInfo.filters) {
                if (filter.attribute.attributeType.input === "select") {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        let parsing = JSON.parse(filter.value)
                        values = parsing.map(e => `/api/attribute-options/${e}`)
                    } else {
                        values = filter.attributeOption.id
                    }
                } else {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        let parsing = JSON.parse(filter.value)
                        values = parsing
                    } else {
                        values = filter.value
                    }
                }
                catFilters.push({
                    new: false,
                    changed: false,
                    code: filter.attribute.id,
                    values,
                    operator: filter.operator,
                    attributeType: { node: filter.attribute },
                    id: filter.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
            }
        }

        this.setState({
            currentNode: nodeInfo,
            typeCategorie: nodeInfo.type,
            cmsPage: nodeInfo.cmsPage?.id,
            categoryContent: nodeInfo.contentCategory?.id,
            catFilters,
            openFormCategories: true,
            editForm: 'edit',
            cat_id: nodeInfo.id,
            identifier: nodeInfo.libelle,
            catActivated: nodeInfo.status,
            categoryMaster: nodeInfo.master?.id,
            catalogSelected: catalogSelected,
            parent: nodeInfo.parent === null ? nodeInfo.id : nodeInfo.parent.id,
        });
    };

    handleTreeChange = (stateName, value) => {
        this.setValue(stateName, value);
        let all = this.state.expanded;

        this.setExpanded(all, value[0]);

        this.setState({ expanded: all });
        this.forceUpdate()
    };

    getAttributeTranslatedValue = (attributes, id, lang) => {
        let attribute = attributes?.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    getTranslatedValueFromData = (datas, id, lang) => {
        let data = datas?.find(e => e.node.locale.id === lang && e.node.attribute.id === id);

        if (!data)
            return null;

        return data.node;
    };

    saveAttributes = (category, uptodate) => {
        return new Promise(async (resolve, reject) => {
            for (let attribute of this.props.attributes.category.attributes.edges) {
                for (let locale of this.props.locales) {
                    let formValue = this.getAttributeTranslatedValue(category.attributes, attribute.node.id, locale.node.id);
                    let currentTranslation = this.getTranslatedValueFromData(category.categoryDatas?.edges, attribute.node.id, locale.node.id);

                    if (formValue) {
                        let resultMedia = null;
                        let isMedia = attribute.node.attributeType.input === 'image';

                        if (isMedia) {
                            if (formValue.media && formValue.media.changed) {
                                // means we have a new media
                                let formData = new FormData();

                                formData.append('file', formValue.media.file);
                                formData.append('type', formValue.media.type);

                                resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                            } else if (category.fromLeft && formValue.media.id) {
                                // means we need to duplicate media
                                let id = formValue.media.id.replace('/api/media-objects/', '');
                                let duplicate = await axios(`${process.env.REACT_APP_API}/media-objects/${id}/duplicate`, 'put');
                                resultMedia = { id: duplicate.response };
                            }
                        }

                        if (currentTranslation && !category.fromLeft && !category.new) {
                            // UPDATE STEP 

                            try {
                                if (isMedia && resultMedia) // remove the old media
                                    await axios(`${process.env.REACT_APP_API}/media-objects/${currentTranslation.media.id.replace('/api/media-objects/', '')}`, 'delete');
                            } catch (e) {
                                console.log(e);
                            }

                            if (!isMedia && currentTranslation.value === formValue.value)
                                continue; // no change

                            if (isMedia && currentTranslation.media.id === formValue.media.id)
                                continue; // no change

                            let variables = {
                                "id": currentTranslation.id,
                                "category": uptodate.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;

                            await this.props.client.mutate({
                                mutation: UPDATE_CATEGORY_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let variables = {
                                "category": uptodate.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;

                            let addDataResult = await this.props.client.mutate({
                                mutation: ADD_CATEGORY_DATA,
                                variables
                            });

                            if (!category?.categoryDatas?.edges) {
                                category = {
                                    categoryDatas: {
                                        edges: []
                                    }
                                }
                            }

                            category.categoryDatas.edges.push({
                                node: addDataResult?.data?.createCategoryData?.categoryData
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await this.props.client.mutate({
                            mutation: DELETE_CATEGORY_DATA,
                            variables: { "id": currentTranslation.id },
                            refetchQueries: [{
                                query: GET_CATEGORIES_ONLY,
                                variables: {
                                    "exists": [{
                                        "catalog": false
                                    }]
                                },
                            }]
                        });
                    }
                }
            }
            resolve();
        });
    };

    handlerMutation = async (attr = false) => {
        this.props.startLoading();

        let variables = {
            "id": this.state.idCatalog,
            "identifier": this.state.catalogIdentifier,
            "libelle": this.state.libelle,
            "description": this.state.description ? this.state.description : null,
            "status": this.state.status,
            "autosync": this.state.autosync,
            "startAt": this.state.startDateNotConverted,
            "endAt": this.state.endDateNotConverted,
        };

        if (attr)
            variables.attributes = this.state.attributesSelected;

        if (this.state.imageCatalog?.id)
            variables.media = this.state.imageCatalog.id;

        this.props.client.mutate({
            mutation: UPDATE_CATALOG,
            variables
        }).then(async (result) => {
            for (let filter of this.state.catalogFilters) {
                let value = null;
                let attributeOption = null;

                if (filter.attributeType.node.attributeType.input === "select") {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                        value = JSON.stringify(arrayRegex)
                    } else {
                        attributeOption = filter.values
                    }
                } else {
                    if (filter.operator === "IN" || filter.operator === "NOT IN") {
                        value = JSON.stringify(filter.values)
                    } else {
                        value = filter.values
                    }
                }

                let queryFilter = null;
                let variablesFilter = null;

                if (filter.new) {
                    queryFilter = CREATE_CATALOG_FILTER;
                    variablesFilter = {
                        operator: filter.operator,
                        createdAt: moment(),
                        catalog: this.state.catalogId,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.changed && !filter.deleted) {
                    queryFilter = UPDATE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                        operator: filter.operator,
                        catalog: this.state.catalogId,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                } else if (filter.deleted) {
                    queryFilter = DELETE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                    }
                }

                if (queryFilter && variablesFilter) {
                    await this.props.client.mutate({
                        mutation: queryFilter,
                        variables: variablesFilter
                    });
                }
            }

            if (attr) {
                this.props.snack(ALERT_SUCCESS, 'Les attributs du catalogue ont été modifiés');
                this.handleToggleDrawer('openFormAttributs');
            } else {
                this.props.snack(ALERT_SUCCESS, 'Le catalogue a été modifié');
                this.handleToggleDrawer('openEditForm');
            }

            this.props.stopLoading();
            this.resetStateCatalog();
            this.getCatalog();
        });
    };

    handlerMutationProducts = () => {

        this.props.startLoading();
        let idListState = this.state.idlist;
        let formatedDatas = {};
        formatedDatas.categories = [];

        for (const [key, value] of Object.entries(this.state.selectedProductByCategorie)) {
            let element = {};
            element.idCategory = parseInt(key.replace('/api/categories/', ''));
            let idList = _.clone(this.state.selectedProductByCategorie[key]);
            let i = 0;
            if (idList) {
                for (let id of idList) {
                    idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                    for (let j = 0; j < idListState.length; j++) {
                        if (parseInt(idListState[j]) === idList[i]) {
                            idListState.splice(j, 1);
                        }
                    }
                    i++;
                }
                element.products = idList;
            }
            if (idList.length > 0) {
                formatedDatas.categories.push(element);
            }
        }

        request(`${process.env.REACT_APP_API}/categories/remove-product`, 'post', formatedDatas).then(response => {
            this.setState({ openForm: false }, async () => {
                let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
                this.setState({
                    pagination,
                    selectedCategorie: '',
                    selectedProductByCategorie: [],
                    reloadProductList: true,
                }, async () => {
                    this.getCatalog(true);
                    // this.handleGetProducts('no-cache');
                    this.props.stopLoading();
                });
            });
        });
    };
    // -- END SAVE TREE

    initProductByCategories = () => {
        let productByCategorie = this.state.productByCategorie || {};
        let selectedProductByCategorie = this.state.selectedProductByCategorie || [];
        for (let categorie of this.state.categories) {
            // let created = selectedProductByCategorie[categorie.id] === undefined;

            productByCategorie[categorie.id] = productByCategorie[categorie.id] || [];
            selectedProductByCategorie[categorie.id] = selectedProductByCategorie[categorie.id] || [];
            if (categorie.productCategories?.edges?.length > 0) {
                for (let product of categorie.productCategories.edges) {
                    let found = productByCategorie[categorie.id].find(e => e.node.id === product.node.product.id);
                    // let foundSelected   = selectedProductByCategorie[categorie.id].find(e => e === product.node.product.id);

                    if (!found)
                        productByCategorie[categorie.id].push(product);

                    // if (!foundSelected && created)
                    //     selectedProductByCategorie[categorie.id].push(product.node.product.id);
                }
            }
        }
        this.setState({
            productByCategorie,
            selectedProductByCategorie,
            selectedCategorie: '',
        });
    }

    resetStateCatalog = () => {
        this.initProductByCategories()
        this.setState({
            selectedCategorie: '',
            categoryMaster: null,
            catalogSelected: null
        });
    };

    setSelectedProducts = values => {
        let productOfCategorie = this.state.selectedProductByCategorie;
        productOfCategorie[this.state.selectedCategorie] = values;

        this.setState({
            selectedProductByCategorie: productOfCategorie
        }, () => { this.forceUpdate() });
    };

    expandNodes = (expanded, stateName) => {
        this.setState({
            [stateName]: toggleExpandedForAll({
                treeData: this.state[stateName],
                expanded
            })
        });
    };

    handleChangeCategorie = async (id) => {
        let pagination = await Pagination.resetPagination(this.state.paginationCheckboxItem.perPage);
        this.setState({
            paginationCheckboxItem: pagination,
            selectedCategorie: id,
            load: true,
        }, async () => {
            this.handleGetProductsPagination('no-cache');
        });
    };

    handleGetProductsPagination = async (policy = "cache-first") => {
        let paginationVars = await Pagination.paginationQueryData(this.state.paginationCheckboxItem);
        let variables = { ...paginationVars };
        variables.id = this.state.idCatalog;
        if (this.state.selectedCategorie) {
            variables.idlist = this.state.selectedCategorie.replace('/api/categories/', '');
        }
        this.props.client.query({
            query: PRODUCTS_BY_CATALOGS_CAT_PAGINATION,
            fetchPolicy: policy,
            variables
        }).then(async result => {
            if (result.data.catalog.categories.length > 0) {
                let pagination = await Pagination.updatePaginationData(this.state.paginationCheckboxItem, result.data.catalog.categories[0].productCategories);
                let products = this.state.productByCategorie;
                products[this.state.selectedCategorie] = result.data.catalog.categories[0].productCategories.edges;
                this.setState({ productByCategorie: products, paginationCheckboxItem: pagination, load: false });
            }
        });
    };

    addProducts = (items) => {
        this.props.startLoading();
        request(`${process.env.REACT_APP_API}/categories/add-product`, 'post', items).then(response => {
            this.setState({ openProductSelector: false }, async () => {
                let pagination = await Pagination.resetPagination(this.state.paginationCheckboxItem.perPage);
                this.setState({
                    paginationCheckboxItem: pagination,
                    reloadProductFormList: true,
                }, async () => {
                    this.handleGetProductsPagination('no-cache');
                    this.props.stopLoading();
                });
            });
        });
    };

    getCatalogTypeName = (identifier) => {
        switch (identifier) {
            case 'permanent':
                return 'Permanent';
            case 'ephemeral':
                return 'Éphémère';
            case 'thematic':
                return 'Thématique';
            default: return 'n/a';
        }
    };

    initAttributs() {
        this.setState({ allGroups: this.props.attributeGroups.filter(e => (!e.node.isSystem) || e.node.identifier === 'default') });
    }

    updatePositionCategoriesCatalog = async (elements, parent, node) => {
        let found = elements.find(e => e.id === node.id);

        if (!found) {
            for (let element of elements) {
                await this.updatePositionCategoriesCatalog(element.children, element.id, node);
            }
        } else {
            let position = 10;

            for (let element of elements) {
                await this.props.client.mutate({
                    mutation: UPDATE_CATEGORY_POSITION,
                    variables: { id: element.id, position, parent },
                });

                position = position + 10;
            }
        }
    };

    handlerUpdatePositionCategoriesCatalog = async (treeData, node, nextTreeIndex) => {
        this.props.startLoading();

        await this.updatePositionCategoriesCatalog(treeData[0].children, treeData[0].id, node);

        this.props.stopLoading();
    };

    componentDidMount() {
        checkRouting(this.props)
        this.getCatalog();
        //this.getAssetByCatalogId();
        //this.getFiltersCatalogs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let calcProduct = 0;

        if (prevState.startDateNotConverted !== this.state.startDateNotConverted) {
            let local = moment.tz.guess();
            let toDateStart = moment.tz(this.state.startDateNotConverted, local).format('L');
            this.setState({
                startDate: toDateStart,
            });
        }
        if (prevState.endDateNotConverted !== this.state.endDateNotConverted) {
            let local = moment.tz.guess();
            let toDateEnd = moment.tz(this.state.endDateNotConverted, local).format('L');
            this.setState({
                endDate: toDateEnd,
            });
        }
        if (prevState.ready !== this.state.ready) {
            this.handleGetCatalogsTypes();
            this.prepareFirstTree(true);
        }
        if (prevState.categories !== this.state.categories) {
            this.setState({ reloadTreeCategories: uuidv4() });
        }
    }

    render() {
        const { anchorEl } = this.state;
        const catalogData = this.state.catalogData;
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value:
                this.props.locales.map((locale) => {
                    return ({
                        value: locale.node.code,
                        label: locale.node.libelle
                    })
                })
        };

        let infoCatSelected = null;

        if (this.state.selectedCategorie) {
            infoCatSelected = this.state.allCategories.find(e => e.id === this.state.selectedCategorie)
            infoCatSelected = getTraductionAttributs('category_name', infoCatSelected?.categoryDatas?.edges, this.state.currentLang)
        }
        return (
            catalogData ? (
                <div style={{ width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                    <TopPanel
                        title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.state.libelle : "Gérer mon Entrepôt"}
                        subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Vue d’ensemble pour gerer le catalogue dans la plateforme" : "Apportez des modifications à votre entrepôt"}
                        gradientColor1={colors.menu.regular}
                        gradientColor2={colors.menu.darker}
                        openForm={this.state.openForm}
                        hasBorder={true}
                        handlerAdd={() => this.handleToggleDrawer('openForm')}
                        textAdd={this.props.t("catalogs.catalogs.organizeCatalog")}
                        buttonAvailable={this.state.allCategoriesReady}
                    />

                    <Grid container direction="column" justifyContent="center">
                        {/* {
                            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                <Grid container justifyContent={'space-between'} alignItems="center">
                                    <Grid item xs={6}>
                                        <BoxCustom style={{ marginBottom: 16 }}>
                                            <ReturnLink variant={'body2'} onClick={this.props.history.goBack}>&lt; Retour</ReturnLink>
                                        </BoxCustom>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container justifyContent={'flex-end'}>
                                            <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : null
                        } */}
                        <GridCustom item lg={12} md={12} xs={12}>
                            <Box>
                                {/* Catalog info */}
                                <Grid container direction="row" alignItems="center" style={{ marginBottom: 24 }}>
                                    <AccordionCustom title={this.props.t('spread_builder.asset_view.infoCatalog')}>
                                        <Grid container justifyContent="center">
                                            <Grid item md={5} xs={12} style={{ paddingRight: this.props.windowWidth > 1249 ? "37.5px" : '0px' }}>
                                                <WrapperImage container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{
                                                        minHeight: this.props.minimalSetup ? 'auto' : 270,
                                                        backgroundImage: this.state.imageCatalog ? `url(${process.env.REACT_APP_MEDIAS}/${this.state.imageCatalog.filePath})` : `url(${NoImage})`
                                                    }}>
                                                </WrapperImage>
                                            </Grid>
                                            <Grid item md={7} xs={12}
                                                style={{
                                                    // paddingLeft: this.props.windowWidth > 1249 ? "37.5px" : '0px',
                                                    paddingTop: this.props.windowWidth > 1249 ? '0px' : '15px'
                                                }}>
                                                <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
                                                    <Grid>
                                                        {/* Title */}
                                                        <Box style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, paddingBottom: '22px' }}>
                                                            <Typography variant='h2' container='h3' style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: "25px", marginBottom: "6px" }}>{this.state.catalogData.libelle}</Typography>
                                                            <TextEllipsis
                                                                title={this.state.catalogData.libelle}
                                                                text={this.state.catalogData.description || this.props.t('spread_builder.asset_view.notDescription')}
                                                                hasModal={true}
                                                            />
                                                        </Box>
                                                        {/* Data */}
                                                        <Grid container style={{ paddingTop: '22px' }}>
                                                            <Grid container xs={7}>
                                                                {/* Status */}
                                                                <Grid container>
                                                                    <Grid item xs={5}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.status')} :</Typography></Grid>
                                                                    <Grid item xs={7}>
                                                                        {/* Status */}
                                                                        <Grid container direction='rox' alignItems='center'>
                                                                            <Grid item>
                                                                                <Grid container alignItems="center" style={{ transform: 'scale(75%)', marginTop: '2px' }}>
                                                                                    <CircleStatus colorcircle={this.state.catalogData.status} />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container alignItems="center">
                                                                                    <TypographyCustom variant={"body2"} colortext={this.state.catalogData.status ? 1 : 0} style={{ fontSize: this.state.catalogData.fontSize ? this.state.catalogData.fontSize : 12, lineHeight: this.state.catalogData.fontSize ? `${this.state.catalogData.fontSize + 2}px` : '16px' }}>
                                                                                        {this.state.catalogData.status
                                                                                            ? this.state.catalogData.statusText
                                                                                                ? this.state.catalogData.statusText
                                                                                                : process.env.REACT_APP_MODE_SPREAD === "hub" ? this.props.t("spread_builder.asset_view.inStock") : this.props.t("spread_builder.asset_view.active")
                                                                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? this.props.t("spread_builder.asset_view.outOfStock") : this.props.t("spread_builder.asset_view.inactive")}
                                                                                    </TypographyCustom>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* Auto Sync */}
                                                                <Grid container alignItems='center'>
                                                                    <Grid item xs={5}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.sync')} :</Typography></Grid>
                                                                    <Grid item xs={7}>
                                                                        <Grid container direction='rox' alignItems='center'>
                                                                            <Grid item>
                                                                                <Grid container alignItems="center" style={{ transform: 'scale(75%)', marginTop: '2px' }}>
                                                                                    <CachedIcon style={{ color: colors.green.regular, marginLeft: '-5px' }}></CachedIcon>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container alignItems="center">
                                                                                    <TypographyCustom variant={"body2"} colortext={this.state.catalogData.status ? 1 : 0} style={{ fontSize: this.state.catalogData.fontSize ? this.state.catalogData.fontSize : 12, lineHeight: this.state.catalogData.fontSize ? `${this.state.catalogData.fontSize + 2}px` : '16px' }}>
                                                                                        {this.state.catalogData.status
                                                                                            ? this.state.catalogData.statusText
                                                                                                ? this.state.catalogData.statusText
                                                                                                : process.env.REACT_APP_MODE_SPREAD === "hub" ? this.props.t("spread_builder.asset_view.inStock") : this.props.t("spread_builder.asset_view.active")
                                                                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? this.props.t("spread_builder.asset_view.outOfStock") : this.props.t("spread_builder.asset_view.inactive")}
                                                                                    </TypographyCustom>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* Accès directe */}
                                                                <Grid container>
                                                                    <Grid item xs={5}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.directAccess')} :</Typography></Grid>
                                                                    <Grid item xs={7}>
                                                                        <Grid container alignItems="center" onClick={() => { this.handleToggleDialog('modalSynchro') }} style={{ cursor: "pointer" }}>
                                                                            <Typography colortext={colors.blue.darker.hue300} style={{ textDecoration: "underline", marginBottom: 4, fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.accessSync')}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container xs={5} style={{ gap: '4px' }}>
                                                                <Grid container>
                                                                    <Grid item xs={7}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.totalProducts')} :</Typography></Grid>
                                                                    <Grid item xs={5}>{!this.state.ready ? <CircularProgress size={"18px"} color={"inherit"} /> : this.state.catalogTotalCount}</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item xs={7}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.totalCategories')} :</Typography></Grid>
                                                                    <Grid item xs={5}>{this.copyArrayOfObjects(this.state.categories).length}</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item xs={7}><Typography variant='subtitle2' container='div' style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.t('spread_builder.asset_view.assignmentAssets')} :</Typography></Grid>
                                                                    <Grid item xs={5}>{this.state.countAssets}</Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* Buttons */}
                                                    <Grid container direction='row' style={{
                                                        gap: '20px',
                                                        marginTop: this.props.windowWidth > 1249 ? '24px' : '44px'
                                                    }}>
                                                        <Grid item style={{ flex: 1 }} >
                                                            <Button
                                                                style={{
                                                                    width: '100%',
                                                                    height: '40px',
                                                                    margin: '0px'
                                                                }}
                                                                text={this.props.t("spread_builder.asset_view.edit")}
                                                                icon={"edit"}
                                                                color={colors.blue.darker.hue300}
                                                                colorHover={colors.white}
                                                                bgcolorhover={colors.blue.lighter.hue600}
                                                                bgcolor={colors.blue.lighter.hue900}
                                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                                stopPropagation={true}
                                                                onClick={() => { this.handleToggleDrawer('openEditForm', true) }}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ flex: 1 }} >
                                                            <Button
                                                                style={{
                                                                    width: '100%',
                                                                    height: '40px',
                                                                    margin: '0px'
                                                                }}
                                                                text={this.props.t("spread_builder.asset_view.manageAttributes")}
                                                                color={colors.blue.darker.hue300}
                                                                colorHover={colors.white}
                                                                bgcolorhover={colors.blue.lighter.hue600}
                                                                bgcolor={colors.blue.lighter.hue900}
                                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                                stopPropagation={true}
                                                                onClick={() => { this.handleToggleDrawer('openFormAttributs', true) }}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ flex: 1 }}>
                                                            <Button
                                                                style={{
                                                                    width: '100%',
                                                                    height: '40px',
                                                                    margin: '0px'
                                                                }}
                                                                text={this.props.t("spread_builder.asset_view.manageProducts")}
                                                                color={colors.blue.darker.hue300}
                                                                colorHover={colors.white}
                                                                bgcolorhover={colors.blue.lighter.hue600}
                                                                bgcolor={colors.blue.lighter.hue900}
                                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                                stopPropagation={true}
                                                                onClick={() => this.handleToggleDrawer('openSelectionProduct', true)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionCustom>
                                </Grid>
                                {/* Listing products */}
                                {
                                    <Box>
                                        <Grid container direction="column" style={{ background: colors.grey.lighter.hue980 }} >
                                            <Grid container direction="row" style={{ maxWidth: '100%' }}>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <ListingProducts
                                                        productBypage={{
                                                            card: [6, 18, 30],
                                                            list: [10, 20, 30]
                                                        }}
                                                        nbperpage={18}
                                                        currentLang={this.state.currentLang}
                                                        windowWidth={this.props.windowWidth}
                                                        openForm={this.state.openForm}
                                                        listCategories={this.copyArrayOfObjects(this.state.categories)}
                                                        selectedCategories={this.state.selectedCategorie}
                                                        catalogId={parseInt(this.state.catalogId.replace('/api/catalogs/', ''))}
                                                        reload={this.state.reloadProductList}
                                                        onReload={() => { this.setState({ reloadProductList: false }) }}
                                                        totalCountCallback={(total) => this.setState({ catalogTotalCount: total })}
                                                        handleLang={this.handleLang}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                <ModalSynchro listAsset={this.state.listAsset} modalSynchro={this.state.modalSynchro} typeCron={this.state.typeCron} onClose={() => this.handleToggleDialog("modalSynchro")} />
                            </Box>
                        </GridCustom>
                    </Grid>
                    <div className='hidden'>
                        {
                            //MODIFICATION DES INFORMATIONS CATALOGUES
                            this.state.openEditForm ?
                                <LayoutBuilder
                                    isSublayout={false}
                                    handleCancel={this.resetStateCatalog}
                                    opened={this.state.openEditForm}
                                    validateButton={true}
                                    handlerMutation={() => this.handlerMutation(false)}
                                    keepColorIcomoon={true}
                                    dataLayout={catalogEditForm(this.state.catalogData, this.handleMediaPicker, this.state.allCatalogsTypes, this.props.attributes.product.attributes)}
                                    allState={this.state}
                                    stateCallback={this.handleInputChange}
                                    errorCallback={this.handleFormError}
                                    drawerWidth={this.state.drawerWidthModified}
                                    handleButtonGroupChange={this.handleButtonGroupChange}
                                    forClose={() => this.handleToggleDrawer('openEditForm', true)}  
                                    stepperButtonAction={[
                                        () => this.setState({
                                            styleInnerContainer: {
                                                boxShadow: 'none',
                                                backgroundInner: colors.white,
                                                backgroundActions: colors.white,
                                                padding: '0px',
                                                height: '450px'
                                            }
                                        }),
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                this.setState({ seeErrors: true });
                                                eventService.fire();
                                                return false;
                                            }

                                            this.handleDrawerWidthChange(300)

                                            return true;
                                        }
                                    ]}
                                    backStepperButtonAction={[
                                        () => this.handleDrawerWidthChange(300),
                                        () => this.setState({
                                            backgroundDrawer: colors.grey.lighter.hue980,
                                            styleInnerContainer: {
                                                boxShadow: 'none',
                                                backgroundInner: colors.white,
                                                backgroundActions: colors.grey.lighter.hue980,
                                                padding: '0px',
                                                height: '630px'
                                            }
                                        }),
                                        () => this.handleDrawerWidthChange(300),
                                    ]}
                                />
                                : null
                        }

                        {
                            //FORMULAIRE GESTION CATEGORIES ET PRODUITS
                            this.state.treeData && this.state.secondTreeData && this.state.filteredCat ?
                                (
                                    <LayoutBuilder
                                        isSublayout={false}
                                        listProducts={this.listProducts}
                                        allCategories={this.state.allCategories}
                                        handleChangeCategorie={this.handleChangeCategorie}
                                        resetReloadListing={() => this.setState({ reloadProductFormList: false })}
                                        selectedCategorie={this.state.selectedCategorie}
                                        productByCategorie={this.state.productByCategorie}
                                        selectedProductByCategorie={this.state.selectedProductByCategorie}
                                        styleInnerContainer={this.state.styleInnerContainer}
                                        backgroundColor={this.state.backgroundDrawer}
                                        treeAddCat={this.addSubcategory}
                                        treeEditCat={this.editCat}
                                        treeDeleteCat={this.deleteCat}
                                        expandNodes={this.expandNodes}
                                        copyCategories={this.copyCategories}
                                        handleCancel={this.resetStateCatalog}
                                        opened={this.state.openForm}
                                        handlerMutation={this.handlerMutationProducts}
                                        dataLayout={catalogEditProductsCategories(this.state.treeData, this.state.secondTreeData, this.state.filteredCat, this.handlerUpdatePositionCategoriesCatalog)}
                                        allState={this.state}
                                        stateCallback={this.handleInputChange}
                                        errorCallback={this.handleFormError}
                                        drawerWidth={this.state.drawerWidthModified}
                                        forClose={() => this.handleToggleDrawer('openForm', true)}
                                        handlerSetup={this.handlerSetup}
                                        validateButton={true}
                                        stepperButtonAction={[
                                            () => this.setState({
                                                styleInnerContainer: {
                                                    boxShadow: 'none',
                                                    backgroundInner: colors.white,
                                                    backgroundActions: colors.white,
                                                    padding: '0px',
                                                    height: '450px'
                                                }
                                            }),
                                            () => {
                                                if (this.hasErrors()) {
                                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                    this.setState({ seeErrors: true });
                                                    eventService.fire();
                                                    return false;
                                                }

                                                this.handleDrawerWidthChange(300)

                                                return true;
                                            }
                                        ]}
                                        backStepperButtonAction={[
                                            () => this.handleDrawerWidthChange(300),
                                            () => this.setState({
                                                backgroundDrawer: colors.grey.lighter.hue980,
                                                styleInnerContainer: {
                                                    boxShadow: 'none',
                                                    backgroundInner: colors.white,
                                                    backgroundActions: colors.grey.lighter.hue980,
                                                    padding: '0px',
                                                    height: '630px'
                                                }
                                            }),
                                            () => this.handleDrawerWidthChange(-800),
                                        ]}
                                        setSelectedProducts={this.setSelectedProducts}
                                        addProducts={() => this.handleToggleDrawer('openProductSelector')}
                                        merchModal={() => this.handleToggleDialog('openMerchModal')}
                                        handleTreeChange={this.handleTreeChange}
                                    />
                                ) : null
                        }
                        {
                            //FORMULAIRE SELECTIONS PRODUITS
                            this.state.openSelectionProduct ?
                            // this.state.type ?
                                (
                                    <LayoutBuilder
                                        isSublayout={false}
                                        noAdd={true}
                                        listProducts={this.listProducts}
                                        allCategories={this.state.allCategories}
                                        handleChangeCategorie={this.handleChangeCategorie}
                                        resetReloadListing={() => this.setState({ reloadProductFormList: false })}
                                        selectedCategorie={this.state.selectedCategorie}
                                        productByCategorie={this.state.productByCategorie}
                                        selectedProductByCategorie={this.state.selectedProductByCategorie}
                                        styleInnerContainer={this.state.styleInnerContainer}
                                        treeAddCat={this.addSubcategory}
                                        treeEditCat={this.editCat}
                                        treeDeleteCat={this.deleteCat}
                                        expandNodes={this.expandNodes}
                                        copyCategories={this.copyCategories}
                                        handleCancel={this.resetStateCatalog}
                                        opened={this.state.openSelectionProduct}
                                        handlerMutation={this.handlerMutationProducts}
                                        dataLayout={catalogEditSelectionsProducts()}
                                        allState={this.state}
                                        stateCallback={this.handleInputChange}
                                        errorCallback={this.handleFormError}
                                        drawerWidth={this.state.drawerWidthModified}
                                        forClose={() => this.handleToggleDrawer('openSelectionProduct', true)}
                                        setSelectedProducts={this.setSelectedProducts}
                                    />
                                ) : null
                        }

                        {
                            //FORMULAIRE GESTION DES ATTRIBUTS
                            this.state.openFormAttributs ?
                            // this.state.allCatalogsTypes && this.state.allGroups ?
                                (
                                    <LayoutBuilder
                                        isSublayout={false}
                                        styleInnerContainer={this.state.styleInnerContainer}
                                        backgroundColor={this.state.backgroundDrawer}
                                        handleCancel={this.resetStateCatalog}
                                        opened={this.state.openFormAttributs}
                                        handlerMutation={() => { this.handlerMutation(true) }}
                                        dataLayout={catalogEditAttributs(this.state.allGroups)}
                                        allState={this.state}
                                        stateCallback={this.handleInputChange}
                                        errorCallback={this.handleFormError}
                                        forClose={() => this.handleToggleDrawer('openFormAttributs', true)}
                                        handlerSetup={this.handlerSetup}
                                        validateButton={true}
                                        stepperButtonAction={[
                                            () => {
                                                if (this.hasErrors()) {
                                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                    this.setState({ seeErrors: true });
                                                    eventService.fire();
                                                    return false;
                                                }
                                                return true;
                                            }
                                        ]}
                                    />
                                ) : null
                        }

                        {
                            //FORMULAIRE DE MODIFICATION DES CATEGORIES
                            this.state.openFormCategories ?
                                <LayoutBuilder
                                    isSublayout={false}
                                    styleInnerContainer={this.state.styleInnerContainer}
                                    opened={this.state.openFormCategories}
                                    forClose={() => this.handleToggleDrawer('openFormCategories', false)}
                                    handlerMutation={this.handleMutationCategories}
                                    dataLayout={this.state.editForm === 'edit' ?
                                        formCategoriesEdit(this.state.cat_id, this.copyArrayOfObjects(this.state.categories), this.state.parent, this.props.attributes?.category.attributes, this.state.currentLang, this.state.errors, this.state.seeErrors, this.handleMediaPicker, this.props.attributes?.product.attributes, true, this.state.typeCategorie, this.state.assetMagentoPages, this.state, this.state.contentCategories, this.state.categoryContent)
                                        : formCategoriesAdd(this.copyArrayOfObjects(this.state.categories), this.state.parent, this.props.attributes?.category.attributes, this.state.currentLang, this.state.errors, this.state.seeErrors, this.handleMediaPicker, true, this.props.t, this.state.typeCategorie, this.state.assetMagentoPages, this.state, this.props.attributes?.product.attributes, this.state.contentCategories, this.state.categoryContent)}
                                    icomoon={this.state.editForm === 'edit' ? 'ico-modifier-categorie' : 'ico-ajouter-categorie'}
                                    allState={this.state}
                                    stateCallback={this.handleInputChange}
                                    errorCallback={this.handleFormError}
                                    // deleteMutation={this.state.editForm === 'edit' ? () => { this.handleToggleDialog('openDialogCategory') } : null}
                                    // deleteText={this.state.editForm === 'edit' ? 'Supprimer la catégorie' : null}
                                    // deleteButton={this.state.editForm}
                                    validateButton={true}
                                    currentLang={this.state.currentLang}
                                    handleLang={this.handleLang}
                                    handleButtonGroupChange={this.handleButtonGroupChange}
                                />
                                : null
                        }

                        {
                            // FORMULAIRE D'AJOUT DE PRODUITS PAR CATEGORIE
                            this.state.openProductSelector ?
                                <LayoutBuilder
                                    isSublayout={false}
                                    drawerWidth={this.state.drawerWidthModified}
                                    opened={this.state.openProductSelector}
                                    dataLayout={productSelectorConfig(infoCatSelected)}
                                    stateCallback={this.addProducts}
                                    allState={this.state}
                                    forClose={() => this.handleToggleDrawer('openProductSelector')}
                                    icomoon={'picto-produit'}
                                    currentLang={this.state.currentLang}
                                    styleInnerContainer={{ boxShadow: 'inherit' }}
                                    expandNodes={this.expandNodes}
                                    handleTreeChange={this.handleTreeChange}
                                />
                                : null
                        }
                    </div>

                    <DialogModal
                        open={this.state.openDialogTreeView}
                        title={`Sélectionnez la catégorie parent :`}
                        notText={true}
                        primaryAction={this.validateNewCategorie} primaryDisabled={this.state.selectCategorie?.node ? false : true}
                        secondaryAction={() => { this.handleToggleDialog('openDialogTreeView') }}
                        windowWidth={this.props.windowWidth}
                    >
                        <SearchBar value={this.state.searchModal} placeholder="Rechercher une catégorie" onChange={(e) => this.handleInputChange('searchModal', e)} />
                        <TreeView
                            typeOfTree={'selectCategorie'}
                            reducedTree={true}
                            dataTree={this.state.secondTreeData}
                            selectCategorie={this.selectCategorie}
                            onChange={treeData => this.handleTreeChange('secondTreeData', treeData)}
                            searchQuery={this.state.searchModal}
                            expand={this.expandNodes}
                            stateName="secondTreeData"
                        />
                    </DialogModal>
                    {
                        this.state.selectedCategorie ?
                            <ProductMerchandising handleToggleDialog={this.handleToggleDialog} openMerchModal={this.state.openMerchModal} categorySelected={this.state.selectedCategorie} catalogId={this.state.catalogId} currentLang={this.state.currentLang} />
                            : null
                    }
                </div>
            ) : <PageLoader />
        );
    }

    prepareFirstTree(init) {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_CATEGORIES_LIGHT_2,
                variables: {
                    "exists": [{
                        "catalog": false
                    }]
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    categoriesSpread: result.data.categories,
                }, () => {
                    this.processTree('treeData', result.data.categories, e => e.parent === null && e.libelle === 'Root', init);
                    resolve();
                });
            });
        });
    }

    prepareSecondTree = async (categories, init, exe = false, skipFetchCategories = false) => {
        categories = categories.sort(function (a, b) {
            return a.position - b.position;
        })

        await this.processTree('secondTreeData', categories, e => e.parent === null, init);

        if (exe) {
            let root = this.state.secondTreeData[0];
            let allCategories = [root.id];

            for (let child of root.children) {
                await this.processCategory(child, root.id, allCategories);
            }

            if (!skipFetchCategories) {
                this.props.client.query({
                    query: GET_CATALOGS_CATEGORIES_BY_ID,
                    variables: { id: this.state.idCatalog },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    let findCatalog = result.data.catalog;

                    this.setState({
                        categories: findCatalog.categories,
                        openDialogTreeView: false,
                    }, () => {
                        this.prepareSecondTree(this.state.categories, true);
                        this.props.stopLoading();
                    });
                });
            } else {
                this.props.stopLoading();
            }
        } else {
            this.props.stopLoading();
        }
    }

    processTree = (stateName, categories, getRoot, init = false) => {
        return new Promise(async (resolve, reject) => {
            let data = categories.filter(getRoot);

            for (let parent of data) {
                this.convertToNode(parent, true, init);
                this.populateChildren(categories, parent, init);
            }

            if (stateName === 'secondTreeData') {
                this.initProductByCategories();
            }
            this.setState({
                [stateName]: this.copyArrayOfObjects(data)
            });
            resolve()
        })
    };

    convertToNode(data, isRoot = false, init = false) {
        let attributes = [];
        // let filters = [];
        if (init) {
            for (let { node } of data.categoryDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;

                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }
            // if (data.categoryFilters && data.categoryFilters.edges.length > 0){
            //     for (let filter of data.categoryFilters.edges){
            //         if (filter){
            //             let getFilterData = {
            //                 ...filter.node,
            //                 attributeType: {node: filter.node.attribute}
            //             }
            //             filters.push(getFilterData)
            //         }
            //     }
            // }
        }

        data.attributes = init ? attributes : data.attributes;
        // data.filters = init ? filters : data.filters;
        data.filters = []; // created when opening a cat in tree

        let nameAttribute = data.attributes.find(e => e.identifier === 'category_name');

        let getTraduction = nameAttribute ? nameAttribute.locales.find(
            translation => translation.code === this.state.currentLang
        ) : null;

        data.title = isRoot ? '/' : getTraduction?.value ?? nameAttribute?.locales[0]?.value ?? data.libelle;
        data.isDirectory = true;
        data.isRoot = isRoot;
        data.dragDisabled = isRoot;
        data.expanded = isRoot ? true : data.expanded;
    }

    populateChildren(cats, parent, init) {
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

        for (let child of parent.children) {
            this.convertToNode(child, false, init);
            this.populateChildren(cats, child, init);
        }
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({ ...a }));

    // goTo = route => this.props.history.push(route);
    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { productId: id }
        });
    };
}

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineCatalogsDetails))));