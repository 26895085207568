import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { withTranslation } from 'react-i18next';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import { ROUTE_CONTENU_LIST_DETAIL } from '../../../../js/constants/route-names';
import { withRouter } from 'react-router';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import DialogModal from '../../../ui/dialog/DialogModal';

const CardImage = styled.img`
    width:100%;
    height:250px;
    object-fit:cover;
    display:block;
`

const CustomGrid = styled(Grid)`
    display:grid;
    grid-template-rows:max-content 60px 40px max-content max-content max-content;
    background-color:white;
    padding:10px;
    height:100%;
    row-gap:10px;
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);


function CardContent(props) {
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const content = props.data;
    const category = content?.node?.contentCategories[0]?.contentCategoryDatas?.edges[0]?.node?.value    
    const attributes = content?.node.contentDatas.edges;
    const status = content?.node.status
    
    let name = '';
    let description = '';
    let image = '';
    let attributesNb = 0
    
    for (let attribute of attributes) {
        if (attribute.node.attribute.isSystem) {            
            name = getTraductionAttributs('content_title', attributes, props.currentLang);
            description = getTraductionAttributs('content_description', attributes, props.currentLang);

            if (description) {
                let doc = document.createElement('div');
                doc.insertAdjacentHTML('beforeend', description);

                description = doc.textContent;
            }

            if (attribute.node.attribute.identifier === 'content_image' && image === '') {
                image = getTraductionAttributs('content_image', attributes, props.currentLang, 'image');
            }
        }else{
            attributesNb++            
        }
    }

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            color: colors.blue.darker.hue300,
            isDisabled: false,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                props.actionButtonSecond();
                // handleClose(e);
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                setOpenDeleteModal(!openDeleteModal);
                // handleClose(e);
            }
        }
    ]

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {name : name}
        });
    };
    return (
        <CustomGrid container>
            <Grid item xs={12}>                
                <CardImage src={image.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : ImgNotFound}/>                
            </Grid>
            <Grid item xs={12} container>                
                <Grid item xs>
                    <Typography style={{fontSize: "25px",fontWeight:"bold",color:colors.black.regular,lineHeight:"30px"}}>
                        <ResponsiveEllipsis
                            unsafeHTML={name}
                            maxLine={2}
                            ellipsis='...'
                            basedOn='letters'
                        />     
                    </Typography>
                </Grid>
                <Grid item xs={"auto"} style={{display:"flex",justifyContent:"flex-end"}}>                      
                    <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />   
                </Grid>
            </Grid>
            <Grid item xs={12}>              
                <ResponsiveEllipsis
                    unsafeHTML={description}
                    maxLine={2}
                    ellipsis='...'
                    basedOn='letters'
                />                
            </Grid>            
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid container spacing={0.5}>
                <Grid item container xs={12}>
                    <Grid item xs={4}>
                        <Typography>Statut :</Typography>    
                    </Grid>
                    <Grid item xs>                                            
                        <StatusInfo
                          status={status}
                          width={"auto"}
                        />                    
                    </Grid>                
                </Grid>
                <Grid item container xs={12}>              
                    <Grid item xs={4}>
                        <Typography>Template :</Typography>    
                    </Grid>
                    <Grid item xs>
                        <span style={{fontWeight:"bold"}}>{category}</span>
                    </Grid>                
                </Grid>
                <Grid item container xs={12}>        
                    <Grid item xs={4}>
                        <Typography>Nombre de catégorie :</Typography>    
                    </Grid>
                    <Grid item xs>
                        <span style={{fontWeight:"bold"}}>{content?.node.contentCategories?.length}</span>
                    </Grid>                                      
                </Grid>
                <Grid item container xs={12}>              
                    <Grid item xs={4}>
                        <Typography>Nombre d'attributs :</Typography>    
                    </Grid>
                    <Grid item xs>
                        <span style={{fontWeight:"bold"}}>{attributesNb}</span>
                    </Grid>                                                      
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button 
                    style={{width:"100%"}}
                    onClick={() => goTo(ROUTE_CONTENU_LIST_DETAIL.replace(':id', content.node.id.replace('/api/contents/', '')))}
                >
                    Voir le contenu
                </Button>
            </Grid>
            <DialogModal
                icon={true}
                type='delete'
                open={openDeleteModal}
                title={'Êtes-vous sûr de vouloir supprimer ce contenu ?'}
                primaryAction={(event) => {event.stopPropagation();props.actionButtonDelete(props.data.node.id);setOpenDeleteModal(false)}}
                secondaryAction={(event) => {event.stopPropagation();setOpenDeleteModal(false)}}
                windowWidth={props.windowWidth}
            >
                {console.log(props)}
                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce contenu ? <strong>Cette action est irréversible</strong></Typography>
            </DialogModal>
        </CustomGrid>
    );
}

export default withTranslation()(withRouter(CardContent));
