import React, { useState, useEffect } from "react";
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMore from "@material-ui/icons/ExpandMore";

// import sliderData from "../../data/hero-sliders/hero-slider-twelve.json";
// import '../../assets/scss/style.scss';
const AccordionDXP = styled(Accordion)`
    width: 100%;
    box-shadow: none !important;
    border:${props=> !props.noborder?`0.5px solid #D0D0D1`:null};
    .MuiAccordionSummary-content, .MuiAccordionSummary-root{
        cursor: ${props => props.forcedExpanded ? "default !important" : "pointer !important"};
    }
    &.Mui-expanded{
        margin: 0;
    }
    .MuiCollapse-root{
        overflow: ${props => props.overflow ? props.overflow: props.expandedFinished && props.isFilter?'visible': "clip"};
        height: ${props => props.overflow ?"calc(100% - 40px)": props.defaultExpanded === false ? 0:"auto"};
    }
`;
const AccordionSummaryDXP = styled(AccordionSummary)`    
    border-bottom:${props=> !props.noborder?props.borderBottomShow?`0.5px solid ${colors.grey.lighter.hue700} !important`:null:null};
    min-height: 40px !important;
    max-height: 40px !important;
    padding: 0 !important;
    .MuiAccordionSummary-content{
        padding:${props=> props.noborder?0: "0 20px"};
        margin: 8px 0 !important;
        align-items: center;
        min-height: 40px;
        border-right:${props=> props.noborder?null: props.borderRight?`0.5px solid ${colors.grey.lighter.hue700}`:null};
    }
    .MuiIconButton-edgeEnd{
        margin-right: 0;
        max-height: 40px;    
    }
`;
const AccordionDetailsDXP = styled(AccordionDetails)`
    padding: ${props => props.custompadding ? props.custompadding : "22px !important"};
    height: ${props => props.scrollable ? props.height ? props.height : "550px" : "auto"};
    overflow: ${props => props.scrollable ? "auto" :props.overflow?props.overflow: props.expanded && props.isFilter? 'visible': "hidden"};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color: ${colors.black.regular};
    .MuiPaper-root{
        overflow: auto;
    }
    p{
        color: ${colors.black.regular};
    }
`;
export default function AccordionCustom(props) {
    let defaultExpanded = props.defaultExpanded
    const [expanded, setExpanded] = useState(typeof defaultExpanded == "boolean" ? defaultExpanded : true);
    const [expandedFinished, setExpandedFinished] = useState(typeof defaultExpanded == "boolean" ? defaultExpanded : true);
    const [borderBottomShow, setBorderBottomShow] = useState(typeof defaultExpanded == "boolean" ? defaultExpanded : true);

    const handle =()=>{
        setExpanded(!expanded)
        if(expandedFinished){
            setExpandedFinished(!expandedFinished)
        }else{
            setTimeout(()=>{
                setExpandedFinished(!expandedFinished)
            },284)
        }
        if(borderBottomShow){
            setTimeout(()=>{
                setBorderBottomShow(!borderBottomShow)
            },284)
        }else{
            setBorderBottomShow(!borderBottomShow)
        }
    }

    return (
        <AccordionDXP
            defaultExpanded={props.defaultExpanded}
            expanded={props.forcedExpanded ? true : expanded}
            forcedExpanded={props.forcedExpanded}
            onChange={() => handle()}
            square={true}
            style={{ ...props.style }}
            noborder={props.noborder}
            overflow={props.overflow}
            expandedFinished={expandedFinished}
            isFilter={props.isFilter}
        >
            <AccordionSummaryDXP
                expandIcon={props.forcedExpanded && !props.hasIcon ? null : <ExpandMore style={{ fill: colors.black.regular }} />}
                borderRight={!props.forcedExpanded}
                noborder={props.noborder}
                borderBottomShow={borderBottomShow}
            >
                {props.title}
            </AccordionSummaryDXP>
            <AccordionDetailsDXP isFilter={props.isFilter} expanded={expanded} overflowChange={props.overflowChange} overflow={props.overflow} scrollable={props.scrollable} height={props.height} custompadding={props.custompadding} style={props.detailsStyles}>
                {props.children}
            </AccordionDetailsDXP>
        </AccordionDXP>
    );
}