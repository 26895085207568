import React from 'react';
import { InputLabel, Container, Grid, Tooltip, Typography, Box, Divider } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import InputBuilder from './InputBuilder';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';
import AccordionCustom from '../../layouts/Accordion/AccordionCustom';
import Translation from '../../layouts/Translation/Translation';


const ContainerCustom = styled(Container)`
    background: ${props => props.backgroundcolor ? props.backgroundcolor : colors.grey.lighter.hue980};
    padding: ${props => props.padding ? props.padding : '32px'};
    @media screen and (max-width: 1280px){
        padding: 24px;
    }
    @media screen and (max-width: 960px){
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 6px;
    }
    .blockContainer{
        box-shadow: ${props => props.styleinnercontainer ? props.styleinnercontainer.boxShadow : '0px 0px 5px rgba(0, 0, 0, 0.1)'};
        padding: ${props => props.styleinnercontainer ? props.styleinnercontainer.padding : '2rem'};
        height: ${props => props.styleinnercontainer ? props.styleinnercontainer.height : '65vh'};
        .containerTree{
            background: ${props => props.styleinnercontainer ? props.styleinnercontainer.backgroundInner : colors.white};
            &>div{
                background: ${props => props.styleinnercontainer ? props.styleinnercontainer.backgroundInner : colors.white};
            }
        }
    }
`;

const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    line-height: 20px;
    // @media screen and (min-width: 1280px){
    //     height: ${props => props.isLogin ? 'auto' : '51px'};
    // }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;
const DividerCustom = styled(Divider)`
    width: 100%;    
    margin:30px 0;
`;

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class FormBuilder extends React.Component {
    checkConditional = (conditional) => {
        if (conditional) {
            let check;

            switch (conditional.type) {
                case 'and':
                default:
                    for (let condition of conditional.conditions) {
                        if (this.checkCondition(condition)) {
                            check = true;
                        } else {
                            check = false;
                            break;
                        }
                    }
                    break;


                case 'or':
                    for (let condition of conditional.conditions) {
                        if (this.checkCondition(condition)) {
                            check = true;
                            break;
                        } else {
                            check = false;
                        }
                    }
                    break;

            }
            return check;
        } else {
            return true;
        }
    }

    checkCondition = (condition) => {
        let conditionCheck;
        if (condition.condition) {
            conditionCheck = this.checkConditional(condition.condition)
        } else {
            conditionCheck = this.props.allState[condition.key] === condition.value
        }
        return conditionCheck
    }
    render() {
        let isAccordion = Array.isArray(this.props.optionsInputs) && this.props.optionsInputs?.filter(e => e && e?.accordion)?.length > 0;
        let index = 1;
        let accordionsArray = [];
        if (isAccordion && this.props.child?.accordionsTitles?.length > 0) {
            for (let element of this.props.child.accordionsTitles) {
                accordionsArray.push(
                    {
                        title: element,
                        childrens: this.props.optionsInputs.filter(e => e && e.accordion === index)
                    }
                )
                index++
            }
        }

        return (
            this.props.child?.component ?
                <this.props.child.component
                    stateCallback={this.props.stateCallback}
                    errorCallback={this.props.errorCallback}
                    allState={this.props.allState}
                    isFormComponent={true}
                    optionsInputs={this.props.child.optionsInputs}
                />
                :
                <ContainerCustom backgroundcolor={isAccordion ? 'transparent' : this.props.backgroundColor} styleinnercontainer={isAccordion ? null : this.props.styleInnerContainer} padding={isAccordion ? '0px' : this.props.padding} style={{ maxWidth: '100%', maxHeight: "auto" }} fixed>
                    <form style={{ maxWidth: 1280 }}>
                        <Grid container direction="row" justifyContent="space-between" spacing={0} style={{ marginBottom: 10 }}>
                            {
                                this.props.optionsInputs?.length > 0 ?
                                    isAccordion ?
                                        accordionsArray.map((accordion, index) => {
                                            if (accordion.childrens.length > 0) {
                                                return (
                                                    <Box mb={2} width="100%" key={`container-input-${accordion.title}-${index}`}>
                                                        <AccordionCustom forcedExpanded={index === 0} detailsStyles={{ backgroundColor: '#FAFAFA' }} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{accordion.title}</Typography>}>
                                                            {accordion.childrens.map(this._renderItem)}
                                                        </AccordionCustom>
                                                    </Box>
                                                )
                                            }
                                        })
                                        : this.props.optionsInputs.map(this._renderItem)
                                    : null
                            }
                        </Grid>
                    </form>
                </ContainerCustom>
        );
    }

    _renderItem = (item, index) => {      
        if (this.props.hideInput) {
            if (!this.props.hideInput.includes(item.stateName)) {
                return (
                    <React.Fragment key={`container-input-${item.label}-${index}`}>
                        <Grid item style={{width: item.doubleInput ? 'calc(100%/2 - 8px)' : item.tripleInput ? `calc(100%/3 - 8px)` : `calc(100%)`}}>
                            <Grid container direction="column" justifyContent="center" alignItems={item.doubleInput || item.tripleInput ? 'center' : 'flex-start'} spacing={0} key={`ItemForm${item.stateName}`} style={{ height: "100%",position:'relative' }}>
                                <Grid container direction="row" spacing={1}>
                                    {item.label ?
                                        <Grid item md={12} xs={12}>
                                            <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                        </Grid> : null
                                    }

                                    <Grid item md={12} xs={12}>
                                        <InputBuilder
                                            value={
                                                item.translated
                                                    ? item.valueWithFlags
                                                        ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value
                                                        : this.props.allState[this.props.allState.currentLang][item.stateName]
                                                    : item.valueWithFlags
                                                        ? this.props.allState[item.stateName]?.value
                                                        : this.props.allState[item.stateName]
                                            }
                                            allState={this.props.allState}
                                            handleTreeChange={this.props.handleTreeChange}
                                            expandNodes={this.props.expandNodes}
                                            treeEditCat={this.props.treeEditCat}
                                            treeDeleteCat={this.props.treeDeleteCat}
                                            copyCategories={this.props.copyCategories}
                                            treeAddCat={this.props.treeAddCat}
                                            input={item}
                                            errorCallback={this.props.errorCallback ? (error) => this.props.errorCallback(item.stateName, error) : () => { }}
                                            stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)}
                                            stateCallbackOriginal={this.props.stateCallback}
                                            handleButtonGroupChange={
                                                this.props.handleButtonGroupChange
                                                    ? this.props.handleButtonGroupChange
                                                    : null
                                            }
                                            gridItems={this.props.gridItems}
                                            loadGrid={this.props.loadGrid}
                                            addProducts={this.props.addProducts}
                                            merchModal={this.props.merchModal}
                                            reloadListing={this.props.resetReloadListing}
                                            catalogDatas={this.props.catalogDatas}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            item.separator ?
                                <DividerCustom />
                                : null
                        }
                    </React.Fragment>
                )
            }
        }
        else {
            if (!item.multipleTree && item.length !== 0) {
                return (
                    this.checkConditional(item.conditional) ?
                        <React.Fragment key={`container-input-${item.label}-${index}`}>
                            {
                                item.titleSection ?
                                <Grid item xs={12} style={{marginBottom: 30,fontSize:16}}>
                                    <Typography variant={'h3'}>Réglage des alertes</Typography>
                                </Grid>
                                :null
                            }
                            <Grid item style={{width: item.doubleInput ? 'calc(100%/2 - 8px)' : item.tripleInput ? `calc(100%/3 - 8px)` : `calc(100%)`}}>
                                <Grid container direction="row" alignItems={item.doubleInput || item.tripleInput ? 'center' : 'flex-start'} spacing={1} key={`ItemForm${item.stateName}`} style={{ height: "100%",position:'relative' }}>
                                    {item.label ?
                                        <Grid item md={12} xs={12}>
                                            <InputLabelCustom islogin={`${this.props.isLogin}`}>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                        </Grid> : null
                                    }
                                    <Grid item md={this.props.isLogin ? 12 : item.label && item.type !== "mapper" ? this.props.useLocking && this.props.allState[this.props.allState.currentLang][item.stateName] ? 11 : 12 : 12} xs={this.props.isLogin ? 12 : this.props.useLocking && this.props.allState[this.props.allState.currentLang][item.stateName] ? 11 : 12}>                                        
                                        <InputBuilder
                                            value={
                                                item.translated
                                                    ? item.valueWithFlags
                                                        ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value
                                                        : this.props.allState[this.props.allState.currentLang][item.stateName]
                                                    : item.valueWithFlags
                                                        ? this.props.allState[item.stateName]?.value
                                                        : this.props.allState[item.stateName]
                                            }
                                            allState={this.props.allState}
                                            input={item}
                                            handleTreeChange={this.props.handleTreeChange}
                                            expandNodes={this.props.expandNodes}
                                            treeEditCat={this.props.treeEditCat}
                                            treeDeleteCat={this.props.treeDeleteCat}
                                            copyCategories={this.props.copyCategories}
                                            treeAddCat={this.props.treeAddCat}
                                            errorCallback={this.props.errorCallback ? (error) => this.props.errorCallback(item.stateName, error) : () => { }}
                                            stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)}
                                            stateCallbackOriginal={this.props.stateCallback}
                                            handleButtonGroupChange={this.props.handleButtonGroupChange
                                                ? this.props.handleButtonGroupChange
                                                : null}
                                            gridItems={this.props.gridItems}
                                            loadGrid={this.props.loadGrid}
                                            addProducts={this.props.addProducts}
                                            merchModal={this.props.merchModal}
                                            resetReloadListing={this.props.resetReloadListing}
                                            catalogDatas={this.props.catalogDatas}
                                        />
                                    </Grid>
                                    {
                                        this.props.useLocking && this.props.allState[this.props.allState.currentLang][item.stateName] ?
                                            <Grid item xs={1} style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}>
                                                {
                                                    this.props.allState[this.props.allState.currentLang][item.stateName]?.isLocked ?
                                                        <BootstrapTooltip title={"Valeur non écrasée lors des imports"}>
                                                            <LockIcon onClick={() => this.props.stateCallback(item.stateName, false, 'isLocked', item.translated)} style={{ fill: colors.red.regular, cursor: 'pointer' }} />
                                                        </BootstrapTooltip>
                                                        :
                                                        <BootstrapTooltip title={"Valeur écrasée lors des imports"}>
                                                            <LockOpenIcon onClick={() => this.props.stateCallback(item.stateName, true, 'isLocked', item.translated)} style={{ fill: colors.green.regular, cursor: 'pointer' }} />
                                                        </BootstrapTooltip>
                                                }
                                            </Grid>
                                            : null
                                    }                                    
                                </Grid>
                            </Grid>
                            {
                                item.separator ?
                                    <DividerCustom />
                                    : null
                            }
                        </React.Fragment>
                        : null
                )
            } else {
                if (!item.explorerManager && item.length !== 0) {
                    return (
                        <React.Fragment key={`container-input-${item.label}-${index}`}>
                            <Grid item xs={item.typeOfTree === "categorieFilter" ? 4 : 6} style={{ flexBasis: '48%' }}>
                                {item.label ?
                                    <Grid item md={12} xs={12}>
                                        <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                    </Grid> : null
                                }
                                <Grid item md={item.label ? this.props.useLocking ? 11 : 12 : 12} xs={this.props.useLocking ? 11 : 12}>
                                    <InputBuilder
                                        value={
                                            item.translated
                                                ? item.valueWithFlags
                                                    ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value
                                                    : this.props.allState[this.props.allState.currentLang][item.stateName]
                                                : item.valueWithFlags
                                                    ? this.props.allState[item.stateName]?.value
                                                    : this.props.allState[item.stateName]
                                        }
                                        allState={this.props.allState}
                                        input={item}
                                        handleTreeChange={this.props.handleTreeChange}
                                        expandNodes={this.props.expandNodes}
                                        treeEditCat={this.props.treeEditCat}
                                        treeDeleteCat={this.props.treeDeleteCat}
                                        copyCategories={this.props.copyCategories}
                                        treeAddCat={this.props.treeAddCat}
                                        stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)}
                                        stateCallbackOriginal={this.props.stateCallback}
                                        handleButtonGroupChange={this.props.handleButtonGroupChange
                                            ? this.props.handleButtonGroupChange
                                            : null}
                                        handleChangeCategorie={this.props.handleChangeCategorie}
                                        selectedCategorie={this.props.selectedCategorie}
                                        productByCategorie={this.props.productByCategorie}
                                        selectedProductByCategorie={this.props.selectedProductByCategorie}
                                        setSelectedProducts={this.props.setSelectedProducts}
                                        allCategories={this.props.allCategories}
                                        gridItems={this.props.gridItems}
                                        loadGrid={this.props.loadGrid}
                                        addProducts={this.props.addProducts}
                                        merchModal={this.props.merchModal}
                                        resetReloadListing={this.props.resetReloadListing}
                                        catalogDatas={this.props.catalogDatas}
                                    />
                                </Grid>
                                {
                                    this.props.useLocking && this.props.allState[this.props.allState.currentLang][item.stateName] ?
                                        <Grid item xs={1} style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}>
                                            {
                                                this.props.allState[this.props.allState.currentLang][item.stateName]?.isLocked ?
                                                    <BootstrapTooltip title={"Valeur non écrasée lors des imports"}>
                                                        <LockIcon onClick={() => this.props.stateCallback(item.stateName, false, 'isLocked', item.translated)} style={{ fill: colors.red.regular, cursor: 'pointer' }} />
                                                    </BootstrapTooltip>
                                                    :
                                                    <BootstrapTooltip title={"Valeur écrasée lors des imports"}>
                                                        <LockOpenIcon onClick={() => this.props.stateCallback(item.stateName, true, 'isLocked', item.translated)} style={{ fill: colors.green.regular, cursor: 'pointer' }} />
                                                    </BootstrapTooltip>
                                            }
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            {
                                item.separator ?
                                    <DividerCustom />
                                    : null
                            }
                        </React.Fragment>
                    )
                } else {
                    if (item.length !== 0) {
                        return (
                            <React.Fragment key={`container-input-${item.label}-${index}`}>
                                <Grid item xs={item.type === "checkboxItem" ? 8 : 6}>
                                    {item.label ?
                                        <Grid item xs={3}>
                                            <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                        </Grid> : null
                                    }
                                    <Grid item xs={item.label ? this.props.useLocking ? 8 : 9 : 12}>
                                        <InputBuilder
                                            value={
                                                item.translated
                                                    ? item.valueWithFlags
                                                        ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value
                                                        : this.props.allState[this.props.allState.currentLang][item.stateName]
                                                    : item.valueWithFlags
                                                        ? this.props.allState[item.stateName]?.value
                                                        : this.props.allState[item.stateName]
                                            }
                                            allState={this.props.allState}
                                            input={item}
                                            handleTreeChange={this.props.handleTreeChange}
                                            expandNodes={this.props.expandNodes}
                                            treeEditCat={this.props.treeEditCat}
                                            treeDeleteCat={this.props.treeDeleteCat}
                                            copyCategories={this.props.copyCategories}
                                            treeAddCat={this.props.treeAddCat}
                                            stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)}
                                            stateCallbackOriginal={this.props.stateCallback}
                                            handleButtonGroupChange={this.props.handleButtonGroupChange
                                                ? this.props.handleButtonGroupChange
                                                : null}
                                            handleChangeCategorie={this.props.handleChangeCategorie}
                                            selectedCategorie={this.props.selectedCategorie}
                                            productByCategorie={this.props.productByCategorie}
                                            selectedProductByCategorie={this.props.selectedProductByCategorie}
                                            setSelectedProducts={this.props.setSelectedProducts}
                                            allCategories={this.props.allCategories}
                                            gridItems={this.props.gridItems}
                                            loadGrid={this.props.loadGrid}
                                            addProducts={this.props.addProducts}
                                            merchModal={this.props.merchModal}
                                            resetReloadListing={this.props.resetReloadListing}
                                            catalogDatas={this.props.catalogDatas}
                                        />
                                    </Grid>
                                    {
                                        this.props.useLocking && this.props.allState[this.props.allState.currentLang][item.stateName] ?
                                            <Grid item xs={1} style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}>
                                                {
                                                    this.props.allState[this.props.allState.currentLang][item.stateName]?.isLocked ?
                                                        <BootstrapTooltip title={"Valeur non écrasée lors des imports"}>
                                                            <LockIcon onClick={() => this.props.stateCallback(item.stateName, false, 'isLocked', item.translated)} style={{ fill: colors.red.regular, cursor: 'pointer' }} />
                                                        </BootstrapTooltip>
                                                        :
                                                        <BootstrapTooltip title={"Valeur écrasée lors des imports"}>
                                                            <LockOpenIcon onClick={() => this.props.stateCallback(item.stateName, true, 'isLocked', item.translated)} style={{ fill: colors.green.regular, cursor: 'pointer' }} />
                                                        </BootstrapTooltip>
                                                }
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                                {
                                    item.separator ?
                                        <DividerCustom />
                                        : null
                                }
                            </React.Fragment>
                        )
                    }
                }
            }
        }
    }
}

export default FormBuilder;
