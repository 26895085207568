import {FORM, STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function discountsEdit(action='add', type, states) {
    console.log('type', type)
    console.log('states', states)
    let title = '';
    switch (type) {
        case 'reduction_produit':
            title = "sur un produit"
            break;
        case 'reduction_panier':
            title = "sur un panier"
            break;
        case 'x_pour_le_prix_de_y':
            title = "X pour le prix de Y"
            break;
        case 'frais_de_livraison':
            title = "frais de livraison"
            break;

        default:
            break;
    }
    let generalInputs = []
    generalInputs.push(
        {
            accordion: 1,
            required : true,
            type : 'text',
            label : 'Nom de la réduction',
            stateName : 'discountName',
            helper: {
                text: 'Indiquer le nom de la réduction ici',
                link: false,
            },
        }, 
        {
            accordion: 1,
            type: 'textareaSimple',
            label: 'Description',
            helper: {
                text: 'Décrivez les détails de la réduction…',
                link: false,
            },
            required: false,
            stateName: 'discountDesc',
        },
        {
            accordion: 1,
            type: 'switch',
            label: 'Actif',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'isActive',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
        {
            accordion: 1,
            type: 'radioSimple',
            direction: 'column',
            label: 'A destination de',
            helper: {
                text: '',
                link: false,
            },
            required: true,
            stateName: 'discountDest',
            value: [
                {
                    value: 'all',
                    label: 'Tous les clients'
                },
                {
                    value: 'onlyClient',
                    label: 'Client connectés seulement'
                },
            ]
        },
        {
            accordion: 1,
            type: 'checkboxes',
            label: 'Nombre maximum d’utilisation de la reduction',
            helper: {
                text: '',
                link: false,
            },
            required: false,
            stateName: 'discountNbUtils',
            value: [
                {
                    value: 'onlyOne',
                    label: 'Limiter à une utilisation par client'
                },
                {
                    value: 'limitTotal',
                    label: 'Limiter le nombre total d’utilisation'
                },
            ]
        },
        states.discountNbUtils.limitTotal ?
            {
                accordion: 1,
                type: 'number',
                label: 'Nombre total d’utilisation',
                helper: {
                    text: 'Indiquez le nombre',
                    link: false,
                },
                required: true,
                stateName: 'discountNbTotal',
            } 
        : null,
        type === "reduction_panier" ?
            {
                accordion: 1,
                type: 'checkboxes',
                label: 'Frais de livraison',
                helper: {
                    text: '',
                    link: false,
                },
                required: false,
                stateName: 'discountFreeDelivery',
                value: [
                    {
                        value: 'free',
                        label: 'Offert'
                    },
                ]
            }
        : null,
        {
            accordion: 1,
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'discountStartAt',
            doubleInput: true
        },
        {
            accordion: 1,
            type: 'checkboxes',
            // label: 'Fixer la date de fin',
            helper: {
                text: '',
                link: false,
            },
            required: false,
            stateName: 'discountHasEnd',
            doubleInput: true,
            value: [
                {
                    value: 'hasIt',
                    label: 'Fixer la date de fin'
                },
            ]
        },
        states.discountHasEnd.hasIt ?
        {
            accordion: 1,
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'discountEndAt',
        } : null,
        type !== "x_pour_le_prix_de_y" ? {
            accordion: 2,
            type: 'radioSimple',
            label: 'Méthode',
            helper: {
                text: '',
                link: false,
            },
            required: true,
            stateName: 'discountMethod',
            value: [
                {
                    value: 'none',
                    label: 'Aucune exigence minimale'
                },
                {
                    value: 'totalPrice',
                    label: 'Montant minimum d\'achat (€)'
                },
                {
                    value: 'totalQty',
                    label: 'Quantité minimum d\'articles'
                }
            ],
            separator: states.discountMethod === 'totalPrice' || states.discountMethod === 'totalQty' ? true : false
        }: null,
        type !== "x_pour_le_prix_de_y" && states.discountMethod === 'totalPrice' ?
            {
                accordion: 2,
                type: 'number',
                label: 'Montant minimum d\'achat',
                helper: {
                    text: 'Indiquez le nombre',
                    link: false,
                },
                required: true,
                stateName: 'discountTotalPrice',
            } 
        : null,
        type !== "x_pour_le_prix_de_y" && states.discountMethod === 'totalQty' ?
            {
                accordion: 2,
                type: 'number',
                label: 'Nombre minimum d\'articles',
                helper: {
                    text: 'Indiquez le nombre',
                    link: false,
                },
                required: true,
                stateName: 'discountTotalQty',
            } 
        : null,
        {
            accordion: 3,
            type: 'radioSimple',
            direction: 'row',
            label: 'Méthode',
            helper: {
                text: '',
                link: false,
            },
            required: true,
            stateName: 'discountMethodReducProducts',
            value: [
                {
                    value: 'code',
                    label: 'Code de réduction'
                },
                {
                    value: 'auto',
                    label: 'Réduction automatique'
                },
            ]
        },
        states.discountMethodReducProducts === 'code' ?
            {
                accordion: 3,
                type: 'text',
                label: 'Code de la réduction',
                helper: {
                    text: 'Indiquez le code de la réduction ici (limite de 255 caractères)',
                    link: false,
                },
                required: true,
                stateName: 'discountReducCode',
            } 
        : null,
        type !== "x_pour_le_prix_de_y" ? {
            accordion: 4,
            type: 'radioSimple',
            direction: 'row',
            label: 'Méthode',
            helper: {
                text: '',
                link: false,
            },
            required: true,
            stateName: 'discountMethodReducValue',
            value: [
                {
                    value: 'percent',
                    label: 'Pourcentage'
                },
                {
                    value: 'flat',
                    label: 'Montant fixe'
                },
            ]
        } : null,
        type === "x_pour_le_prix_de_y" ?{
            accordion: 4,
            type: 'checkboxes',
            label: 'Méthode',
            helper: {
                text: '',
                link: false,
            },
            required: false,
            stateName: 'discountXYHasMin',
            value: [
                {
                    value: 'hasIt',
                    label: 'Montant minimum d\'achat'
                },
            ],
            separator: true
        } : null,
        type === "x_pour_le_prix_de_y" ?{
            accordion: 4,
            type: 'decimal',
            label: `Quantité`,
            helper: {
                text: `NB de produits panier`,
                link: false,
            },
            required: true,
            stateName: 'discountXYQty',
            tripleInput: true
        } : null,
        type === "x_pour_le_prix_de_y" ?{
            accordion: 4,
            type: 'decimal',
            label: `Offre`,
            helper: {
                text: `Nombre de produits remisé`,
                link: false,
            },
            required: true,
            stateName: 'discountXYOfferQty',
            tripleInput: true
        } : null,
        type === "x_pour_le_prix_de_y" ?{
            accordion: 4,
            type: 'decimal',
            label: `Pourcentage`,
            helper: {
                text: `S'applique automatiquement`,
                link: false,
            },
            required: true,
            stateName: 'discountXYPercent',
            suffix : "%",
            tripleInput: true
        } : null,
        type !== "x_pour_le_prix_de_y" ? {
            accordion: 4,
            type: 'decimal',
            label: `Valeur de l'offre ${states.discountMethodReducValue === 'percent' ? 'en %' : 'en €'}`,
            helper: {
                text: `Indiquez la valeur de la réduction ${states.discountMethodReducValue === 'percent' ? 'en %' : 'en €'}`,
                link: false,
            },
            required: true,
            stateName: 'discountReducValue',
            separator: type !== "reduction_panier" ? true : false
        } : null,
        type !== "reduction_panier" ? 
            type === "x_pour_le_prix_de_y" ? 
                states.discountXYHasMin.hasIt ?
                    null
                :
                {
                    accordion: 4,
                    type: 'radioSimple',
                    direction: 'row',
                    label: 'Application',
                    helper: {
                        text: '',
                        link: false,
                    },
                    required: true,
                    stateName: 'discountApplicationReduc',
                    value: [
                        {
                            value: 'in',
                            label: 'Aux produits spécifiques'
                        },
                        type !== "x_pour_le_prix_de_y" ? {
                            value: 'not_in',
                            label: 'Tous, sauf'
                        } : null,
                        type !== "x_pour_le_prix_de_y" ? {
                            value: 'all',
                            label: 'Tous les produits'
                        } : null,
                    ]
                }
            :
            {
                accordion: 4,
                type: 'radioSimple',
                direction: 'row',
                label: 'Application',
                helper: {
                    text: '',
                    link: false,
                },
                required: true,
                stateName: 'discountApplicationReduc',
                value: [
                    {
                        value: 'in',
                        label: 'Aux produits spécifiques'
                    },
                    type !== "x_pour_le_prix_de_y" ? {
                        value: 'not_in',
                        label: 'Tous, sauf'
                    } : null,
                    type !== "x_pour_le_prix_de_y" ? {
                        value: 'all',
                        label: 'Tous les produits'
                    } : null,
                ]
            }
        : null,
        type !== "reduction_panier" && states.discountApplicationReduc !== "all" ? 
            type === "x_pour_le_prix_de_y" ? 
                states.discountXYHasMin.hasIt ?
                    null
                :
                    {
                        accordion: 4,
                        type: 'productExplorer',
                        label: 'Liste des produits',
                        helper: {
                            text: '',
                            link: false,
                        },
                        required: true,
                        stateName: 'discountListProducts',
                    }
            :
            {
                accordion: 4,
                type: 'productExplorer',
                label: 'Liste des produits',
                helper: {
                    text: '',
                    link: false,
                },
                required: true,
                stateName: 'discountListProducts',
            } 
        : null,
        type === "x_pour_le_prix_de_y" && states.discountXYHasMin.hasIt ?
            {
                accordion: 4,
                type: 'decimal',
                label: `Montant minimum`,
                helper: {
                    text: `Indiquer le montant minimum ici`,
                    link: false,
                },
                required: true,
                stateName: 'discountTotalPrice',
                suffix : "€",
            }
        : null,
    )
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? `+ Créer une réduction ${title}` : `Modifier une réduction ${title}`,
        subTitleForm: `Veuillez compléter les champs ci-dessous pour configurer votre réduction`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add' ? 'Créer cette réduction' : 'Modifier la réduction',
        formConfig: {
            type: FORM,
            children:[
                {
                    accordionsTitles:['Information sur la reduction','Condition d’achat minimum', type === "x_pour_le_prix_de_y" ? 'X pour le prix de Y' : 'Montant de la réduction sur les produits', type === "x_pour_le_prix_de_y" ? 'Le/la client(e) achète' : 'Valeur de la réduction'],
                    optionsInputs:generalInputs
                }
            ]
        }
    }
    return form;
}