import { FormInputTypes, MenuTypes } from '../../../shareable/types';

export { default as SectionTitle } from './_sectionTitle.config';
export { default as Slider } from './_slider.config';
export { default as SliderWithRightImages } from './_sliderWithRightImages.config';
export { default as SliderMultiple } from './_sliderMultiple.config';
export { default as Footer } from './_footer.config';
export { default as ProductGrid } from './_productGrid.config';
export { default as ImageRow } from './_imageRow.config';
export { default as GiftFinder } from './_giftFinder.config';
export { default as ThreeImagesRow } from './_threeImagesRow.config';
export { default as ThreeImagesGrid } from './_threeImagesGrid.config';
export { default as BlocText } from './_blocText.config';
export { default as Contact } from './_contact.config';
export { default as Wishlist } from './_wishlist.config';
export { default as Landing } from './_landing.config';
export { default as Product } from './_product.config';
export { default as Text } from './_text.config';
export { default as EmailContact } from './_emailContact.config';
export { default as EmailWishlist } from './_emailWishlist.config';
export { default as InformationBanner } from './_informationBanner.config';
export { default as LinkBanner } from './_linkBanner.config';
export { default as Category } from './_category.config';
export { default as TextImage } from './_textImage.config';
export { default as SliderProduct } from './_sliderProduct.config';
export { default as TextVideo } from './_textVideo.config';
export { default as VideoOnly } from './_videoOnly.config';

export const MinisiteConfig = {
    logo: {
        id: "main-logo",
        name: "Logo",
        type: FormInputTypes.IMAGE,
        value: "https://via.placeholder.com/200x100"
    },
    moto: {
        id: "moto",
        name: "Slogan",
        type: FormInputTypes.TEXTAREA,
        value: "Lorem ipsum"
    },
    copyright: {
        id: "copyright",
        name: "Copyright",
        type: FormInputTypes.TEXT,
        value: "© 2020 [Votre entreprise]. All Rights Reserved."
    },
    menu: {
        id: "header-menu",
        name: "Menu",
        type: FormInputTypes.MENU,
        value: [
            { 
                type: MenuTypes.LINK,
                readonly: true,
                id: "home",
                name: "Accueil",
                value: "/"
            },
            {
                type: MenuTypes.LINK,
                readonly: true,
                id: "contact",
                name: "Contact",
                value: "/contact"
            }
        ]
    }
};