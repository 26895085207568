import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/image-fake-card.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import StoreIcon from '@material-ui/icons/Store';
import _ from 'lodash';
import UpdateIcon from '@material-ui/icons/Update';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import * as moment from 'moment';
import StyleIcon from '@material-ui/icons/Style';
import {withTranslation} from 'react-i18next'
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../assets/selection.json'

const InfoStock = styled(Grid)`
    position: absolute;
    top: -32px;
    right: -32px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    background: ${colors.black.regular};
    opacity: 0.8;
    font-weight: bold;
    line-height: normal;
`
const WrapperAttr = styled(Grid)`
    max-width: 200px;
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`
const WrapperImage = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
`
const ContainerCard = styled(Box)`
    display: block;
    position: relative;
    height: 225px;
    /* height: auto; */
    width: 100%;
    cursor: pointer;
    &:hover{
        &> div{
            opacity: 1;
            button{
                top: 0px;
            }
        }
    }
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    height: ${props => props.nbline ? 26 * props.nbline + 'px' : '52px'};
    -webkit-line-clamp: ${props => props.nbline ? props.nbline : '2'};
    -webkit-box-orient: vertical;
`
const Sku = styled(Typography)`
    color: ${colors.grey.lighter.hue600};
    font-size: 12px;
`;
const ProductAttr = styled.div``;
const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
    font-size: 13px;
`
const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: -16px;
    top: -16px;
    right: 0;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    background: rgba(2, 115, 165, 0.3);
    opacity: 0;
    transition: all 0.5s;
    cursor: pointer;
    & > button{
        max-width: 235px;
        top: 20px;
        transition: all 0.8s;
    }
`

const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`

function CardProduct(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product;
    //const attributes = product.productDatas.edges;
    const smallCard = props.smallCard ? props.smallCard : false;
    
    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';
    
    // for(let attribute of attributes){
    //     if(attribute.node.attribute.isSystem){
    //         imageSelected = attributes.filter(e => e.node.media !== null)
    //         getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)

    //         name = getTraductionAttributs('product_name', attributes, props.currentLang);

    //         if(attribute.node.attribute.identifier === 'product_image' && image === ''){
    //             image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
    //         }
    //     }
    // }

    name = product.flatProducts?.edges[0]?.node.name;
    image = product.flatProducts?.edges[0]?.node.image;

    let sku = product.flatProducts?.edges[0]?.node.sku;
    let productStatus = product.flatProducts?.edges[0]?.node.status;
    let categoriesNb = product.flatProducts?.edges[0]?.node.categoriesNb || 0;
    let catalogsNb = product.flatProducts?.edges[0]?.node.catalogsNb || 0;
    let childrenNb = product.flatProducts?.edges[0]?.node.childrenNb || 0;
    let imagesNb = product.flatProducts?.edges[0]?.node.imagesNb || 0;
    let completude = product.flatProducts?.edges[0]?.node.completude;

    let nbAttrImages = product.flatProducts?.edges[0]?.node.imagesMaxNb || 1;

    // let localStorage_GET_NB_ATTR_IMAGES = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // if(!localStorage_GET_NB_ATTR_IMAGES){
    //     let localStorageAttributes  = localStorage.getItem('ATTRIBUTES');
    //     let attributes = JSON.parse(localStorageAttributes);
    //     for (let attribute of attributes.product?.attributes?.edges){
    //         if(attribute.node.attributeType.input=='image'){
    //             nbAttrImages++;
    //         }
    //     } 
    //     localStorage.setItem('GET_NB_ATTR_IMAGES',nbAttrImages);
    // } else {
    //     nbAttrImages = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // }

    let catalogList = []
    /*
    for (let category of props.product.productCategories.edges){
        if (category.node.category.catalog){
            if (catalogList.indexOf(category.node.category.catalog.libelle) === -1){
                catalogList.push(category.node.category.catalog.libelle)
            }
        }
    }*/

    const goTo = props.routeProduct;

    let stock = 0;
    let cart = 0;
    let reappro = 0;
    let realTime = 0;
    if(product.stocks?.allInfoStocks){
        cart = product.stocks.allInfoStocks.cart.value;
        for(let item of product.stocks.allInfoStocks.stock){
            stock += item.value;
        }
        for(let item of product.stocks.allInfoStocks.reappro){
            reappro += item.value;
        }
        realTime = stock - cart;
    }

    let date = moment(product?.stocks?.allInfoStocks?.reappro[0].date);
    let diff = date.diff(moment(), 'days') + 1;
    return(
        <ContainerCard onClick={props.inForm ? props.buttonOnClick ? props.buttonOnClick : goTo : null}>
            <Grid container style={{position: 'relative', height: '100%', width: '100%'}}>
                {
                    product.stocks?.allInfoStocks ?
                        <InfoStock item>
                            <span>Dispo</span>
                            <span style={{fontSize: 24, display:'flex', flexDirection:'row', alignItems:'center'}}><UpdateIcon /> <span style={{marginLeft: 8}}>{realTime}</span></span>
                        </InfoStock>
                    : null
                }
                
                <Grid item xs={props.type === "dashboard" ? 4 : 6} style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-between',height:"100%"}}>
                    <Box pr={props.type === "dashboard" ? 2 : 2} height={"100%"}>
                        <WrapperImage container alignItems="center" justifyContent="center" style={{minHeight: props.minimalSetup || props.inForm ? 'auto' : "auto" ,height:"100%"}}>
                            {
                                <img src={image !== '' && image!== null ? `${process.env.REACT_APP_MEDIAS}/${image}` : NoImage} style={{maxHeight: "100%", maxWidth: '100%', width: '100%', height: '100%',objectFit:image !== '' && image!== null ? "contain" : "cover"}} alt={name} />
                            }
                        </WrapperImage>
                    </Box>
                    {/* {
                        process.env.REACT_APP_MODE_SPREAD !== "hub" && !props.minimalSetup ?
                            <Box pr={2} mt={2} display="flex" style={{alignItems: "center", justifyContent: "space-around", flexDirection: props.windowWidth > 900 ? "row" : "column"}}>
                                {
                                    <Box position="relative" display="inline-flex" mb={props.windowWidth > 900 ? 0 : 2}>
                                        <CircularProgressCustom variant="determinate" thickness={3} value={completude} size={40}/>
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="h5" component="div" color="textSecondary">
                                                {completude > 0 ? `${Math.round(completude)}%` : `${completude}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                                {
                                    imageSelected ? 
                                    (
                                        <Grid container alignItems="center" style={{width:"auto"}}>
                                            <PhotoLibraryOutlinedIcon  style={{color: colors.grey.regular}}/>
                                            <Typography variant="body1" style={{marginLeft: '8px'}}><SpanColor>{imagesNb}</SpanColor>/{nbAttrImages}</Typography>
                                        </Grid>
                                    ) : null
                                }
                            </Box>
                        : null
                    } */}
                </Grid>
                <Grid item xs={props.type === "dashboard" ? 8 : 6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Sku variant="body2">Ref. {product.sku}</Sku>
                            <Box style={{width: 'auto'}}>
                                <StatusInfo fontSize={13} size={8} status={product.status}></StatusInfo>
                            </Box>
                        </Box>
                        <Box pt={1}>
                            <Title variant="h4" nbline={props.type === 'dashboard' ? '1' : '2' }>{name || 'N/A'}</Title>
                        </Box>
                        {}
                        <Box pt={1}>
                            <ProductAttr>
                                {
                                    !props.minimalSetup ? 
                                    <>
                                        {
                                            childrenNb !== 0?
                                                <WrapperAttr container justifyContent='space-between'>
                                                    <Typography> {props.t('products.list.cardproduct.nbVariants')} :</Typography>
                                                    <Typography variant="body1"><strong>{childrenNb}</strong></Typography>
                                                </WrapperAttr>
                                            :null
                                        }
                                        <WrapperAttr container justifyContent='space-between'>
                                            <Typography> {props.t('products.list.cardproduct.inCategory')} :</Typography>
                                            <Typography variant="body1"><strong>{categoriesNb}</strong></Typography>
                                        </WrapperAttr>
                                        <WrapperAttr container justifyContent='space-between'>
                                            <Typography> {props.t('products.list.cardproduct.inCatalog')} :</Typography>
                                            <Typography variant="body1"><strong>{catalogsNb}</strong></Typography>
                                        </WrapperAttr>
                                    </>
                                    : null
                                }
                            </ProductAttr>
                            
                        </Box>
                    </Box>
                    <Box display="flex" style={{alignItems: "center", justifyContent: "space-between", flexDirection: "row",paddingTop:15}}>
                        {
                            imageSelected ? 
                            (
                                <Grid container alignItems="center" style={{width:"auto"}}>
                                    <IcomoonReact iconSet={iconSet} icon={'icon-medias'} size={20} color={colors.black.regular} />
                                    <Typography variant="body1" style={{marginLeft: '8px', color: colors.black.regular, fontWeight: 'bold'}}>{imagesNb}/{nbAttrImages}</Typography>
                                </Grid>
                            ) : null
                        }
                        {
                            <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={colors.green.regular} mb={props.windowWidth > 900 ? 0 : 2}>
                                <CircularProgressCustom variant="determinate" thickness={2.5} value={completude} style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }} size={50}/>
                                <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    disableShrink
                                    thickness={2.5}
                                    size={50}
                                />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" style={{color: colors.green.regular, fontWeight: 'bold'}}>
                                        {completude > 0 ? `${Math.round(completude)}%` : `N/A`}
                                    </Typography>
                                </Box>
                            </BoxCustom>
                        }
                    </Box>
                </Grid>
                {
                    product.stocks?.infoStocks ?
                    
                        <Grid container style={{paddingTop: 16}}>
                            {
                                product.stocks.infoStocks.length > 0 ?
                                    product.stocks.infoStocks.map((stock) => {
                                        if (stock.value > 0){
                                            return(
                                                <Grid container alignItems="center">
                                                    {stock.type === 'store' ?
                                                        <StoreIcon style={{color: colors.grey.regular}}/>
                                                        : 
                                                        <EqualizerIcon style={{color: colors.grey.regular}}/>
                                                    }
                                                    <Typography variant="body1" style={{marginLeft: 4}}>{stock.libelle}: <b><SpanColor>{stock.value}</SpanColor></b></Typography>
                                                </Grid>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                : null
                            }
                        </Grid>
                    : null
                }
                {
                    product.stocks?.allInfoStocks ?
                        <Grid container style={{marginTop: 8}} alignItems="center">
                            <PlayForWorkIcon style={{color: colors.grey.regular}}/>
                            <Typography variant="body1" style={{marginLeft: 4}}>Réassort: <b><SpanColor>{reappro > 0 ? reappro : 'Aucune'}</SpanColor></b> le {moment(product.stocks.allInfoStocks.reappro[0].date).format('DD/MM/YYYY')} { diff > 0 ? `(J+${diff})` : null }</Typography>
                        </Grid>
                    : null
                }
                
            </Grid>
            
            {smallCard ? 
                <ButtonCustom 
                    disableElevation={true} 
                    stopPropagation={props.inForm} 
                    text={props.t(props.buttonText ? props.buttonText : 'products.list.cardproduct.seeProduit')} 
                    bgColor={colors.green.regular} 
                    onClick={props.buttonOnClick ? props.buttonOnClick : goTo} style={{marginTop:10}} 
                />
            :null}
            {/* {
                <ContainerButton style={{justifyContent: 'center', alignItems: 'center'}} onClick={props.buttonOnClick ? props.buttonOnClick : goTo}>
                    {!smallCard ? 
                        <ButtonCustom 
                            stopPropagation={props.inForm} 
                            disableElevation={true} 
                            text={props.t(props.buttonText ? props.buttonText : 'products.list.cardproduct.seeProduit')} 
                            bgColor={colors.green.regular} 
                            onClick={props.buttonOnClick ? props.buttonOnClick : goTo} 
                            fontsize={ 16 } 
                            style={props.type === "dashboard" ? {height: '100%', padding: 6} : {width: "100%", padding: 10}} 
                        />
                    :null}
                </ContainerButton>
            } */}
        </ContainerCard>
    );
}

export default withTranslation()(CardProduct);